import { useContext, useEffect, useState } from "react";
import { GameContext } from "../Context/GameContextProvider";
import { storeGameData } from "../Store/game/actions";

const useCountDown = (startTime = 60, endCallBack = () => {}, condition) => {
  // const [counter, setCounter] = useState(startTime);
  const [initialize, setInitialize] = useState(false);
  const {
    gameState: { counter },
    gameDispatch,
  } = useContext(GameContext);

  useEffect(() => {
    if (initialize) {
      gameDispatch(storeGameData("counter", startTime));
    }
    setInitialize(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);

  // Third Attempts
  useEffect(() => {
    let timer;
    if (condition) {
      timer =
        counter > 0 &&
        setInterval(
          () => gameDispatch(storeGameData("counter", counter - 1)),
          1000
        );
      if (counter === 0 && initialize) {
        endCallBack();
      }
    }
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, condition]);
  // console.log(`counter`, counter);

  return { counter };
};

export default useCountDown;
