import {
  LOAD_GAME,
  LOAD_GAME_FAILED,
  LOAD_GAME_SUCCESS,
  RESET_GAME,
  RE_RENDER,
  STORE_GAME_DATA,
  STORE_PLAYER,
} from "./actionTypes";

export const loadGame = () => ({
  type: LOAD_GAME,
});

export const loadGameSuccess = (data) => ({
  type: LOAD_GAME_SUCCESS,
  payload: { data },
});

export const loadGameFailed = (data) => ({
  type: LOAD_GAME_FAILED,
  payload: { data },
});

export const storeGameData = (name, data) => ({
  type: STORE_GAME_DATA,
  payload: { name, data },
});

export const rerenderGameData = () => ({
  type: RE_RENDER,
});

export const resetGame = (data) => ({
  type: RESET_GAME,
  navigate: data,
});

export const storePlayer = (name, data) => ({
  type: STORE_PLAYER,
  payload: { name, data },
});
