import React, { forwardRef } from "react";
import {
  styled,
  Typography,
  Box,
  CardContent,
  Grid,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

// Components
import FillImageBox from "../../../components/FillImageBox/FillImageBox";
import CardHeader from "../CardHeader";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: "0.6rem",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#262626",
  borderRadius: "0.4rem 0.4rem 0 0",
  "& .accordion-title": {
    color: "#fff",
    textAlign: "center",
    width: "100%",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: "#191919",
  borderRadius: "0 0 0.4rem 0.4rem",
}));

const FillBlankCard = styled(Box)(({ theme }) => ({
  borderRadius: "10px",
  border: "3px solid #fff",
  flex: 1,
  padding: "1rem",
  display: "flex",

  "> .MuiCardContent-root": {
    display: "flex",
    flex: 1,
    width: "100%",
    padding: "0px !important",

    "> textarea": {
      width: "100%",
      borderRadius: "5px",
      border: "none",
      boxShadow: "none",
      outline: "none",
    },
  },
}));

const SinglePanel = forwardRef((props, ref) => {
  const {
    panel,
    title,
    selectedCards,
    imagesList,
    panelIndex,
    isLoading,
    isDisabled,
    handleClickCard,
  } = props;

  const dis =
    isLoading ||
    (panelIndex[panel] === selectedCards[panel].pIndex &&
      !!selectedCards[panel]?.value?.trim()) ||
    panelIndex[panel] === selectedCards[panel].pIndex;

  return (
    <div ref={ref}>
      <CardHeader title={title} selectedCards={selectedCards} />
      <Accordion
        expanded={true}
        onChange={() => {
          // handleChange(panel)
        }}
      >
        <AccordionSummary expandIcon={null}>
          <Typography className="accordion-title" variant="h6" component="h1">
            Select {selectedCards[panel]?.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={5}>
            {imagesList[panelIndex[panel]].map((singleImage, i) => {
              const d =
                panelIndex[panel] === selectedCards[panel].pIndex &&
                singleImage.prompt === selectedCards[panel]?.value?.trim();

              return (
                <Grid item xs={12} sm={6} md={4} key={`panel_images_${i}`}>
                  <FillImageBox
                    isLoading={isLoading}
                    imageArray={[singleImage]}
                    showBoxLabel={false}
                    picLabel={singleImage.prompt}
                    background="transparent"
                    allowClickImg={false}
                    picLabelStyles={{
                      textTransform: "capitalize",
                      fontSize: "1.25rem",
                    }}
                    customStyles={{
                      cursor: "pointer",
                      opacity:
                        d || !selectedCards[panel]?.value?.trim()
                          ? 1
                          : panelIndex[panel] === selectedCards[panel].pIndex &&
                            !selectedCards[panel]?.value
                          ? 1
                          : 0.3,
                    }}
                    onClick={() =>
                      isDisabled || isLoading
                        ? null
                        : handleClickCard(panel, singleImage, panelIndex[panel])
                    }
                  />
                </Grid>
              );
            })}
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={`panel_images_${5}`}
              display="flex"
            >
              <FillBlankCard sx={{ opacity: dis ? 0.3 : 1 }}>
                <CardContent>
                  <textarea
                    placeholder="Write your own prompt!"
                    onBlur={(ev) => {
                      if (!isLoading) {
                        handleClickCard(
                          panel,
                          ev.target.value,
                          panelIndex[panel]
                        );
                      }
                    }}
                    className="describe_image"
                    disabled={dis}
                  />
                </CardContent>
              </FillBlankCard>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
});

export default SinglePanel;
