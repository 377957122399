import { Box, styled } from "@mui/material";
import React from "react";

const assignBgColor = (type) => {
  switch (type) {
    case "primary":
      return "#5271FF";
    case "danger":
      return "#FF0000";
    case "success":
      return "#479616";
    case "purple":
      return "#5523E2";
    case "purpleBtn":
      return "#5510E8";
    case "yellowBtn":
      return "#d2d20b";
    case "orangeBtn":
      return "#E59C0F";
    default:
      return "#fff";
  }
};

const BoxContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",
}));

const StepBox = styled(Box)(({ variant }) => ({
  padding: "10px 50px",
  border: "1px solid #000000",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  fontSize: "24px",
  fontWeight: "700",
  background: assignBgColor(variant),
  textAlign: "center",
  borderRadius: "30px",
  display: "inline-block",
  color: variant ? "#fff" : "#000",
}));

const SubText = styled(Box)(({ theme }) => ({
  fontSize: "24px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  color: "#fff",
  fontWeight: "700",
  margin: "15px 0",
}));

const Step = ({ variant, stepName, subText }) => {
  return (
    <BoxContainer className="stepName">
      <StepBox className="stepText" variant={variant}>
        {stepName}
      </StepBox>
      <SubText>{subText}</SubText>
    </BoxContainer>
  );
};

export default Step;
