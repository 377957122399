import { useMsal } from "@azure/msal-react";
import { Box, Container, Slide, styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, {
  // forwardRef,
  useContext,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import AnonymousTabContent from "../../components/Duel/AnonymousTabContent";
import AuthTab from "../../components/Duel/AuthTab";
import { AuthContext } from "../../Context/AuthContextProvider";
import { GameContext } from "../../Context/GameContextProvider";
import { resetGame } from "../../Store/game/actions";
import "../../styles/game.css";
import micorsoft from "./../../images/image 10.png";
// Components
// import ImageUpIcon from "./../../images/image-up.svg";
// import SpecialBtnBg from "./../../images/special-btn-bg.svg";
// import SpecialBtnLgBg from "./../../images/special-btn-lg-bg.svg";
// import SpecialBtnSmBg from "./../../images/special-btn-sm-bg.svg";
// import ChooseTheBest from "./chooseTheBest";
// import DrawCard from "./drawCard";
// import FillInTheBlanks from "./fillInTheBlanks";

// MainDiv is the main container for the page
const MainDiv = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(5),
  },
}));

// const PlayerOneBlanks = forwardRef((props, ref) => (
//   <FillInTheBlanks ref={ref} {...props} />
// ));
// const PlayerTwoBlanks = forwardRef((props, ref) => (
//   <FillInTheBlanks ref={ref} {...props} />
// ));

export default function Duel() {
  const navigate = useNavigate();
  // selectedFormType is the index of the selected form type
  const { accounts } = useMsal();
  const {
    gameState: { duelTab },
    changeGameTab,
    gameDispatch,
  } = useContext(GameContext);
  const { authState, handleLogin } = useContext(AuthContext);
  // const { socket } = useContext(SocketContext);
  // const [selectedFormType, setSelectedFormType] = useState(0);

  //  inputs is the array of inputs for the form
  // const [inputsPlayer1, setInputsPlayer1] = useState([]);
  // console.log(`🦄 ~ file: index.js:51 ~ Duel ~ inputsPlayer1`, inputsPlayer1);
  // const [inputsPlayer2, setInputsPlayer2] = useState([]);
  // console.log(`🦄 ~ file: index.js:53 ~ Duel ~ inputsPlayer2`, inputsPlayer2);

  // Active Step
  // const [activeStep, setActiveStep] = useState("draw-card");
  // console.log(`activeStep`, activeStep, selectedFormType);

  // const drawCardRef = useRef();
  // const fbp1Ref = useRef();
  // const fbp2Ref = useRef();
  // const ctbRef = useRef();

  // const getActiveStepComponent = (key) => {
  //   switch (key) {
  //     case "draw-card":
  //       return (
  //         <DrawCard
  //           ref={drawCardRef}
  //           selectedFormType={selectedFormType}
  //           setSelectedFormType={(value) => {
  //             setSelectedFormType(value);
  //             setActiveStep("fbp1");
  //           }}
  //         />
  //       );
  //     case "fbp1":
  //       return (
  //         <PlayerOneBlanks
  //           ref={fbp1Ref}
  //           stepName="Player 1: Fill in the blanks!"
  //           selectedFormType={selectedFormType}
  //           inputs={inputsPlayer1}
  //           setInputs={setInputsPlayer1}
  //           handleOnSubmit={() => {
  //             setActiveStep("fbp2");
  //           }}
  //           btnText="Submit and Pass to Player 2"
  //           btnBg={SpecialBtnBg}
  //           stepVariant="danger"
  //         />
  //       );
  //     case "fbp2":
  //       return (
  //         <PlayerTwoBlanks
  //           ref={fbp2Ref}
  //           stepName="Player 2: Fill in the blanks!"
  //           selectedFormType={selectedFormType}
  //           inputs={inputsPlayer2}
  //           setInputs={setInputsPlayer2}
  //           handleOnSubmit={() => {
  //             setActiveStep("ctb");
  //           }}
  //           btnText="Generate Image"
  //           btnBg={SpecialBtnSmBg}
  //           btnIcon={ImageUpIcon}
  //           stepVariant="primary"
  //         />
  //       );
  //     case "ctb":
  //       return (
  //         <ChooseTheBest
  //           ref={ctbRef}
  //           btnText="Which one do you prefer? Player 1 or Player 2?"
  //           btnBg={SpecialBtnLgBg}
  //           selectedFormType={selectedFormType}
  //           inputsPlayer1={inputsPlayer1}
  //           inputsPlayer2={inputsPlayer2}
  //         />
  //       );
  //     default:
  //       return (
  //         <DrawCard
  //           ref="draw-card"
  //           selectedFormType={selectedFormType}
  //           setSelectedFormType={(value) => {
  //             setSelectedFormType(value);
  //             setActiveStep("fbp1");
  //           }}
  //         />
  //       );
  //   }
  // };

  const reDirect = () => {
    changeGameTab("authenticated");
    navigate("/duel/join-game");
  };

  useEffect(() => {
    if (authState?.user?.username || authState?.user?.name) {
      reDirect();
    } else {
      gameDispatch(resetGame());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.user?.username, accounts?.length]);

  // When the selectedFormType changes, update the inputs
  useEffect(() => {
    // generatePrompt(gameDispatch, {
    //   body: {
    //     card_text:
    //       "Anime of the protagonist, (legged mammal), charging and releasing a (color) energy blast towards their opponent, a (vegetable).",
    //   },
    // });
    // if (selectedFormType > -1) {
    //   setInputsPlayer1(stringToArray(formTypes[selectedFormType].prompt));
    //   setInputsPlayer2(stringToArray(formTypes[selectedFormType].prompt));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // selectedFormType

  const getTabContent = (tab) => {
    switch (tab) {
      case "anonymous":
        return <AnonymousTabContent />;

      default:
        return (
          <div className="authLogin">
            <h4 className="login_text">Log into your account</h4>
            <button onClick={() => handleLogin()} className="microsoft">
              <img src={micorsoft} alt="micorsoft" />
              <span>Continue with Microsoft</span>
            </button>
          </div>
        );
    }
  };

  return (
    <MainDiv className="">
      <Container maxWidth="xl">
        <Grid container>
          <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <div className="gamePage">
              <Grid item xs={12} sm="6">
                <div className="gamePage_authCard">
                  <AuthTab duelTab={duelTab} changeGameTab={changeGameTab} />
                  {getTabContent(duelTab)}
                </div>
              </Grid>
            </div>
          </Slide>
          {/* {getActiveStepComponent(activeStep)} */}
        </Grid>
      </Container>
    </MainDiv>
  );
}
