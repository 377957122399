export const LOAD_GAME = "LOAD_GAME";
export const LOAD_GAME_SUCCESS = "LOAD_GAME_SUCCESS";
export const LOAD_GAME_FAILED = "LOAD_GAME_FAILED";

export const STORE_GAME_DATA = "STORE_GAME_DATA";

export const RE_RENDER = "RE_RENDER";

export const RESET_GAME = "RESET_GAME";

export const STORE_PLAYER = "STORE_PLAYER";
