export let formTypes = [
  {
    value: 0,
    label: "Anime",
    prompt:
      "Anime of the protagonist, (legged mammal), charging and releasing a (color) energy blast towards their opponent, a (vegetable).",
  },
  {
    value: 1,
    label: "Digital Painting",
    prompt:
      "Digital painting of a (wild animal) standing in front of (famous landmark).",
  },
  {
    value: 2,
    label: "Colored Pencil",
    prompt:
      "Colored pencil drawing of (fictional character) enjoying a picnic in a (famous town).",
  },
  {
    value: 3,
    label: "Disco Party",
    prompt:
      "The most 70s (amphibian) in a white suit leaning up against a convertible with (City) in the background on a disco album cover.",
  },
  {
    value: 4,
    label: "Renaissance Italy",
    prompt:
      "Renaissance painting of (famous historical figure), holding a (beauty accessory) and standing in front of a (famous Italian landmark)",
  },
  {
    value: 5,
    label: "Ancient Egypt",
    prompt:
      "Hieroglyph of the (Egyptian god) making offerings to the god of (fun food)",
  },
];

export let formTypes_telephone = [
  {
    value: 0,
    label: "Children's Book",
    prompt:
      "Children’s book cover with a very hungry (dinosaur type) made out of (any kind of paper) on the front.",
  },
  {
    value: 1,
    label: "Movie Poster",
    prompt:
      "(Cute mammal) coming up from the bottom of the sea with (color) scuba gear on a movie poster.",
  },
  {
    value: 2,
    label: "Anime",
    prompt:
      "Anime of the protagonist, (legged mammal), charging and releasing a (color) energy blast towards their opponent, a (vegetable).",
  },
  {
    value: 3,
    label: "Drawing",
    prompt:
      "Charcoal drawing of (fictional character) deep in thought, leaning over with a strong (unique body type) physique.",
  },
  {
    value: 4,
    label: "Self Portrait",
    prompt:
      "Self portrait of a (time period) photographer their darkroom in a (Polaroid or other style) photo.",
  },
  {
    value: 5,
    label: "Monet",
    prompt:
      "Impressionist painting of a garden (lawn ornaments) and a (shade of color) pond.",
  },
  {
    value: 6,
    label: "____ for Hands",
    prompt:
      "The main character rides through (a city center) on a bike, but with (plural noun) for hands.",
  },
  {
    value: 7,
    label: "Digital Painting",
    prompt:
      "Digital painting of a (wild animal) standing in front of (famous landmark).",
  },
  {
    value: 8,
    label: "Colored Pencil",
    prompt:
      "Colored pencil drawing of (fictional character) enjoying a picnic in a (famous town).",
  },
  {
    value: 9,
    label: "Comic Book Hero",
    prompt:
      "Graphic novel cover featuring (a cute animal) and fighting a (adjective) villain in a futuristic city.",
  },
  {
    value: 10,
    label: "Cake",
    prompt:
      "The world's most delicious (industrial item), topped with (fruit).",
  },
  {
    value: 11,
    label: "Disco Party",
    prompt:
      "The most 70s (amphibian) in a white suit leaning up against a convertible with (City) in the background on a disco album cover.",
  },
  {
    value: 12,
    label: "Watercolor Food Spaceship",
    prompt:
      "A watercolor illustration depicting a group of friends eating (ham-shaped) (ridiculous snack) in a spaceship.",
  },
  {
    value: 13,
    label: "Action Figure",
    prompt:
      "(wrench-wielder) as an action figure, fighting (comic book villian) with a a giant fist, in the style of Picasso.",
  },
  {
    value: 14,
    label: "Scenic Landscape",
    prompt:
      "Scenic landscape painting of a (terrain type) mountain range, with a (mossy shade) lake in the foreground and a (shade of blue) sky in the background.",
  },
  {
    value: 15,
    label: "Street Art",
    prompt: "Street art mural of a (reptile) holding a (musical instrument).",
  },
  {
    value: 16,
    label: "Abstract Art",
    prompt:
      "Abstract art piece featuring swirling lines and shapes in shades of (a type of layer cake), representing the feeling of (emotion).",
  },
  {
    value: 17,
    label: "Graffiti",
    prompt:
      "Graffiti piece featuring the words '(catchphrase)' in bold letters, surrounded by (adjective) designs and patterns",
  },
  {
    value: 18,
    label: "Photography",
    prompt:
      "Black and white photography portrait of a (kind of worker) gazing off into the distance, with a (type of happiness) expression on their face",
  },
  {
    value: 19,
    label: "Sculpture",
    prompt:
      "Sculpture of a (adjective) hand, made out of (whatchamacallit), reaching out as if to grasp something just out of reach",
  },
  {
    value: 20,
    label: "Graphic Design",
    prompt:
      "Graphic design poster for a (music festival), featuring a illustration of a (avian) playing a (instrument)",
  },
  {
    value: 21,
    label: "Fantasy Book Cover",
    prompt:
      "(Cuddly animal) wielding a (type of weapon) in front of a (castle) on a fantasy book cover",
  },
  {
    value: 22,
    label: "Horror Film",
    prompt:
      "(Something funny) emerging from the shadows around a (type of house) on a horror movie poster",
  },
  {
    value: 23,
    label: "Sci-Fi Poster",
    prompt:
      "Sci-fi movie poster featuring a (type of spaceship) flying through a (color) galaxy",
  },
  {
    value: 24,
    label: "Architecture",
    prompt:
      "Photograph of a (adjective) building, taken at night with the (type of lights) shining",
  },
  {
    value: 25,
    label: "Surrealist Art",
    prompt:
      "A (time keeping device) melting into a (utensil) in a surrealist painting",
  },
  {
    value: 26,
    label: "Fashion Illustration",
    prompt:
      "Fashion illustration featuring a (adjective) model wearing a red dress in a (landscape)",
  },
  {
    value: 27,
    label: "Street Photography",
    prompt:
      "Street photography of a well dressed (type of feline) in a busy (midsized city)",
  },
  {
    value: 28,
    label: "American Gothic",
    prompt:
      "Two (facial expression) (type of dog) farmers holding pitchforks in an American Gothic style",
  },
  {
    value: 29,
    label: "Quilt",
    prompt:
      "Patchwork quilt with a bunch of (characters) on it, made out of (type of fabric)",
  },
  {
    value: 30,
    label: "Political Cartoon",
    prompt:
      "Political cartoon featuring an (animal) as a famous politician on the steps of (political building)",
  },
  {
    value: 31,
    label: "Pop Art",
    prompt:
      "Pop art painting of a (restaurant job) in a iconic pose, with bold colors and (adjective) patterns",
  },
  {
    value: 32,
    label: "Astronomy",
    prompt: "Photograph of a (color) nebula, taken by the (famous telescope)",
  },
  {
    value: 33,
    label: "Geography",
    prompt:
      "Topographical map of (famous mountain range), showing the different (land feature)",
  },
  {
    value: 34,
    label: "Biology",
    prompt:
      "Diagram of a (adjective) (type of animal) cell, showing the different (cell organelles)",
  },
  {
    value: 35,
    label: "History",
    prompt:
      "Painting of the (historical event), showing (a conspicuous tourist) in the foreground",
  },
  {
    value: 36,
    label: "Ancient Egypt",
    prompt:
      "Hieroglyph of the (Egyptian god) making offerings to the god of (fun food)",
  },
  {
    value: 37,
    label: "Medieval Europe",
    prompt:
      "Stained glass window depicting a (medieval profession) holding a (modern tool)",
  },
  {
    value: 38,
    label: "Renaissance Italy",
    prompt:
      "Renaissance painting of (famous historical figure), holding a (beauty accessory) and standing in front of a (famous Italian landmark)",
  },
  {
    value: 39,
    label: "Victorian England",
    prompt:
      "Victorian engraving of a (blue collar job) and standing in front of a (famous British landmark)",
  },
  {
    value: 40,
    label: "Ancient Greece",
    prompt:
      "Greek vase painting depicting a (animal) and a (fictional character) dancing",
  },
  {
    value: 41,
    label: "Wildlife Photography",
    prompt:
      "Wildlife photography of a (anything) in its natural habitat, capturing the creature in motion",
  },
  {
    value: 42,
    label: "Still Life Photography",
    prompt:
      "Still life photography of a (table setting), with a vase of (color) flowers and a (fruit) bowl in the foreground",
  },
  {
    value: 43,
    label: "Photorealism",
    prompt:
      "Photorealistic painting of a (complex machine), capturing every detail with precision",
  },
  {
    value: 44,
    label: "Travel Photography",
    prompt:
      "Travel photography featuring a (angle) view of (tall landmark) in (different city)",
  },
  {
    value: 45,
    label: "Postcard",
    prompt:
      "Vintage-style postcard featuring a two (animals) crossing (a famous bridge)",
  },
  {
    value: 46,
    label: "Sports Photography",
    prompt:
      "Sports photography capturing a (famous underdog) in action during a (unique sport) game",
  },
  {
    value: 47,
    label: "Soda Can",
    prompt:
      "Soda can featuring a (sport) team's logo and colors on a group of (marsupials)",
  },
  {
    value: 48,
    label: "Space Villian",
    prompt:
      "Giant, planet destroying (tropical fruit) shooting a laser at (something that's no longer a planet)",
  },
  {
    value: 49,
    label: "Folk Art",
    prompt:
      "Folk art piece depicting (migratory birds) playing a (musical instrument) together",
  },
  {
    value: 50,
    label: "Playground Sculpture",
    prompt:
      "Sculpture of a giant (food item) made out of (material) that doubles as a playground for kids",
  },
  {
    value: 51,
    label: "Wacky Street Performance",
    prompt:
      "Street performance of a group of (type of clothing) clad performers juggling (object) while riding (mode of transportation)",
  },
  {
    value: 52,
    label: "Installation Art",
    prompt:
      "Installation art piece featuring a giant (type of object) made out of (material)",
  },
  {
    value: 53,
    label: "Fictional Character",
    prompt:
      "A children's book cover where (fictional character) and (person) Jump Over a (noun)",
  },
];
