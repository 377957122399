import React from "react";
import { Box } from "@mui/material";

// Components
import FillImageBox from "../../../components/FillImageBox/FillImageBox";
import CardHeader from "../CardHeader";

export default function FinalResult({ selectedCards, isLoading, finalResult }) {
  return (
    <>
      <CardHeader title="Final" selectedCards={selectedCards} />
      <Box display="flex" justifyContent="center">
        <FillImageBox
          isLoading={isLoading}
          imageArray={finalResult.arrayOfImages}
          showBoxLabel={false}
          picLabel={finalResult.title}
          background="transparent"
          allowClickImg={false}
          maxWidth={512}
          maxHeight={575}
          customStyles={{
            marginTop: "1.15rem",
          }}
          picLabelStyles={{
            textTransform: "capitalize",
            fontSize: "1.25rem",
          }}
          customSkeletonStyles={{ height: 512 }}
        />
      </Box>
    </>
  );
}
