import {
  LOAD_GAME,
  LOAD_GAME_FAILED,
  LOAD_GAME_SUCCESS,
  RESET_GAME,
  RE_RENDER,
  STORE_GAME_DATA,
  STORE_PLAYER,
} from "./actionTypes";

export const GAME_INITIAL_STATE = {
  loading: false,
  error: "",
  duelTab: "anonymous",
  joining: false,
  reRender: false,
  gameData: {},
  currentUser: {},
  votedPlayer: {},
  creator: {},
  players: [],
  activeStep: "draw-card",
  userId: "",
  aiPlayers: [],
  selectedFormType: 0,
  counter: 1,
  adding: false,
  playersInput: [],
  playerImage: [],
  winner: {},
  winnerType: "",
  winnerData: {},
};

export const gameStorageKey = "ai-puzzle-game";

export const gameInitializer = (initialValue = GAME_INITIAL_STATE) =>
  JSON.parse(localStorage.getItem(gameStorageKey)) || initialValue;

const gameReducer = (state, action) => {
  switch (action.type) {
    case STORE_GAME_DATA:
      state = {
        ...state,
        [action.payload.name]: action.payload.data,
      };
      break;
    case RE_RENDER:
      state = {
        ...state,
        reRender: !state.reRender,
      };
      break;
    case LOAD_GAME:
      state = { ...state };
      break;
    case LOAD_GAME_SUCCESS:
      state = { ...state };
      break;
    case STORE_PLAYER:
      state = {
        ...state,
        [action.payload?.name]: [
          ...state[action.payload?.name],
          action.payload?.data,
        ],
      };
      break;
    case LOAD_GAME_FAILED:
      state = { ...state };
      break;
    case RESET_GAME:
      state = {
        ...state,
        loading: false,
        error: "",
        duelTab: "anonymous",
        joining: false,
        reRender: false,
        gameData: {},
        // currentUser: {},
        votedPlayer: {},
        creator: {},
        players: [],
        activeStep: "draw-card",
        // userId: "",
        aiPlayers: [],
        selectedFormType: 0,
        counter: 1,
        adding: false,
        playersInput: [],
        playerImage: [],
        winner: {},
        winnerType: "",
        winnerData: {},
      };
      action?.navigate && action?.navigate("/duel");
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default gameReducer;
