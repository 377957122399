import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
} from "react";
import { loginRequest } from "../authConfig";
import { loadProfileData, logoutUser } from "../Store/auth/actions";
import { createUser } from "../Store/auth/Api";
import authReducer, {
  authStorageKey,
  AUTH_INITIAL_STATE,
  initializer,
} from "../Store/auth/reducer";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [bottomSheetVisible, setBottomSheetVisible] = React.useState(false);
  const [state, dispatch] = useReducer(
    authReducer,
    AUTH_INITIAL_STATE,
    initializer
  );

  useEffect(() => {
    // This is a side-effect and belongs in an effect
    localStorage.setItem(authStorageKey, JSON.stringify(state));
  }, [state]);

  const { instance, accounts } = useMsal();
  // console.log(`accounts`, accounts);

  const isAuthenticated = useIsAuthenticated();
  console.log(` isAuthenticated:`, isAuthenticated, instance);

  const handleLogin = async () => {
    try {
      const response = await instance.loginRedirect(loginRequest);
      console.log("login", response);
    } catch (err) {
      console.log("LOGIN ERROR: ", err);
    }
  };

  const handleUserLogout = async () => {
    try {
      const response = await instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
      if (response) {
        // console.log("logout", response);
        dispatch(logoutUser());
      }
    } catch (err) {
      console.log("LOGIN ERROR: ", err);
    }
  };

  const handleRequestProfileData = useCallback(() => {
    if (accounts?.length && instance?.initialized) {
      loadProfileData({ instance, accounts, dispatch });
      const currentUser = accounts?.[0];
      if (state?.user?.username !== currentUser?.username) {
        createUser(accounts, dispatch);
      }
    }
  }, [instance, accounts, state?.user?.username]);

  useEffect(() => {
    handleRequestProfileData();
  }, [handleRequestProfileData]);

  useEffect(() => {
    if (instance?.initialized && accounts?.length === 0) {
      dispatch(logoutUser());
    }
  }, [accounts, instance?.initialized]);

  return (
    <AuthContext.Provider
      value={{
        authState: state,
        authDispatch: dispatch,
        isAuthenticated,
        handleLogin,
        handleUserLogout,
        bottomSheetVisible,
        setBottomSheetVisible,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
