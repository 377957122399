import { Container, styled } from "@mui/material";
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import { Context as appContext } from "../context";

const MyHeader = styled("header")(({ theme }) => ({
  margin: "30px 25%",
  borderBottom: "1px solid #4D4D4D",
  paddingBottom: "18px",
  display: "flex",
  // gap: "40px",
  alignItems: "center",
  justifyContent: "space-around",
  fontSize: "22px",
  "& a": {
    color: "#7e7e7e",
    textDecoration: "none",
    textAlign: "center",
    // width:"100px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "auto",
      flex: "0 0 33.333%",
      marginBottom: "15px",
      marginTop: "15px",
    },
  },
  [theme.breakpoints.down("md")]: {
    margin: "15px 0",
    flexWrap: "wrap",
    justifyContent: "center",
    paddingBottom: "0",
  },
}));

const Line = styled("p")(({ theme }) => ({
  backgroundColor: "#fff",
  height: "3px",
  display: "block",
  position: "relative",
  top: "20px",
  [theme.breakpoints.down("md")]: {
    top: "15px",
  },
}));

function Header() {
  const location = useLocation();
  const { currentUser } = useContext(appContext);

  return (
    // <header style = {{display: 'flex', justifyContent: 'space-around'}}>

    //         <Link style = {{textDecoration: 'none', color: 'white', fontSize: '20px', width: '15%', height: '50px', borderRadius: '10px', backgroundColor: '#5a54ff', display: 'flex', justifyContent: 'center', alignItems: 'center', }} to = '/layoutprompt'>
    //             Prompt
    //         </Link>

    //         <Link style = {{textDecoration: 'none', color: 'white', fontSize: '20px', width: '15%', height: '50px', borderRadius: '10px', backgroundColor: '#5a54ff', display: 'flex', justifyContent: 'center', alignItems: 'center', }} to = '/'>
    //             Builder
    //         </Link>

    //         <Link style = {{textDecoration: 'none', color: 'white', fontSize: '20px', width: '15%', height: '50px', borderRadius: '10px', backgroundColor: '#5a54ff', display: 'flex', justifyContent: 'center', alignItems: 'center', }} to = '/gallery'>
    //             Gallery
    //         </Link>
    // </header>
    <>
      {location.pathname !== "/login" && (
        <Container>
          <MyHeader>
            <Link
              to="/"
              style={{
                color: location.pathname === "/" ? "#fff" : "#7e7e7e",
              }}
            >
              Cards
              {location.pathname === "/" && <Line></Line>}
            </Link>
            <Link
              to="/builder"
              style={{
                color: location.pathname === "/builder" ? "#fff" : "#7e7e7e",
              }}
            >
              Builder
              {location.pathname === "/builder" && <Line></Line>}
            </Link>
            <Link
              to="/telephone"
              style={{
                color: location.pathname === "/telephone" ? "#fff" : "#7e7e7e",
              }}
            >
              Telephone
              {location.pathname === "/telephone" && <Line></Line>}
            </Link>
            <Link
              to="/duel"
              style={{
                color:
                  location.pathname === "/duel" ||
                  location.pathname?.split("/")?.[1] === "duel"
                    ? "#fff"
                    : "#7e7e7e",
              }}
            >
              Duel
              {location.pathname === "/duel" && <Line></Line>}
            </Link>
            <Link
              to="/gallery"
              style={{
                color: location.pathname === "/gallery" ? "#fff" : "#7e7e7e",
              }}
            >
              Gallery
              {location.pathname === "/gallery" && <Line></Line>}
            </Link>
            {currentUser && (
              <Link
                to="/freeform"
                style={{
                  color: location.pathname === "/freeform" ? "#fff" : "#7e7e7e",
                }}
              >
                FreeForm
                {location.pathname === "/freeform" && <Line></Line>}
              </Link>
            )}
          </MyHeader>
        </Container>
      )}
    </>
  );
}

export default Header;
