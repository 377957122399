import { Box, CardContent, Grid, Slide, styled } from "@mui/material";
import React, { forwardRef } from "react";

// Components
import SpecialButton from "../../components/SpecialButton/SpecialButton";
import Step from "../../components/Step";
import { formTypes_telephone } from "../../data/formTypes";
import Img from "../../images/AI_charades_2.png";

const FillBlankCard = styled(Box)(({ theme }) => ({
  background: "#000",
  borderRadius: "10px",
  border: "3px solid #fff",
  color: "#979797",
  maxWidth: "320px",
  margin: "0 auto",
  fontSize: "20px",
  lineHeight: "1.9",
  "& label": {
    color: "#fff",
  },
  "& input": {
    padding: "5px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "none",
    outline: "none",
    fontSize: "20px",
    marginBottom: "10px",
  },
}));

const FillInTheBlanks = forwardRef((props, ref) => {
  const {
    selectedFormType,
    inputs,
    setInputs,
    handleOnSubmit,
    stepName,
    btnText,
    btnBg,
    btnIcon,
    stepVariant,
    isLoading,
  } = props;

  const handleChange = (event, index) => {
    const values = [...inputs];
    values[index].value = event.target.value;
    setInputs(values);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleOnSubmit();
    }
  };

  return (
    <Slide ref={ref} direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid item xs={12}>
        <Step variant={stepVariant} stepName={stepName} />
        {selectedFormType > -1 && (
          <FillBlankCard>
            <CardContent>
              {formTypes_telephone[selectedFormType].prompt
                .split("(")
                .map((word, index) => {
                  const words = word.substring(0, word.indexOf(")"));
                  const otherWords = word.substring(word.indexOf(")") + 1);
                  const input = inputs.find((input) => input.name === words);
                  // console.log(`input`, input, words, otherWords);
                  if (input) {
                    return (
                      <div key={index}>
                        <label key={index}>
                          <input
                            type="text"
                            placeholder={input.name}
                            value={input.value}
                            onChange={(event) =>
                              handleChange(event, input.inputIndex)
                            }
                            onKeyDown={handleEnter}
                            disabled={isLoading}
                            required
                            // style={{ maxWidth: `${input.name.length / 1.8}em` }}
                          />
                        </label>
                        {otherWords && otherWords + " "}
                      </div>
                    );
                  }

                  return word + " ";
                })}
            </CardContent>
            <Box p={2} mt={4}>
              <img src={Img} alt="ai generated" />
            </Box>
          </FillBlankCard>
        )}
        {btnText && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop="1.5rem"
          >
            <SpecialButton
              btnBg={btnBg}
              btnText={btnText}
              btnIcon={btnIcon}
              onClick={handleOnSubmit}
              disabled={isLoading}
              loading={isLoading}
            />
          </Box>
        )}
      </Grid>
    </Slide>
  );
});

export default FillInTheBlanks;
