import React, { forwardRef } from "react";
import { Box, Grid, Slide, Typography, styled } from "@mui/material";

// Components
import FillImageBox from "../../components/FillImageBox/FillImageBox";
import SpecialButton from "../../components/SpecialButton/SpecialButton";
import SpecialBtnLgBg2 from "./../../images/special-btn-lg-bg-2.svg";
import YellowBtnBg from "./../../images/yellow-btn.svg";
import BlueBtnBg from "../../images/blue-btn-no-text.svg";
import RedBtnBg from "../../images/red-btn-no-text.svg";
import GreenBtnBg from "../../images/green-btn-no-text.svg";
import PurpleBtnBg from "../../images/purple-btn-no-text.svg";
import YellowBtnBg2 from "../../images/yellow-btn-no-text.svg";
import OrangeBtnBg from "../../images/orange-btn-no-text.svg";

const ORContainer = styled("div")(({ theme }) => ({
  backgroundColor: "#262626",
  color: "white",
  padding: "0.25rem 0.75rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "25px",
  "> p": {
    fontSize: "0.75rem",
  },
  alignSelf: "end",
  marginBottom: "-3.5rem",
  position: "absolute",
  bottom: 0,
  left: -60,
}));

const LeftTitle = styled("div")(({ theme }) => ({
  backgroundImage: `url(${YellowBtnBg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  width: "100%",
  padding: "1rem 0",
}));

const labelImages = {
  primary: BlueBtnBg,
  danger: RedBtnBg,
  success: GreenBtnBg,
  purpleBtn: PurpleBtnBg,
  yellowBtn: YellowBtnBg2,
  orangeBtn: OrangeBtnBg,
};

const ChooseTheBest = forwardRef((props, ref) => {
  const { playerList, isLoading } = props;

  return (
    <Slide ref={ref} direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid container item xs={12} paddingBottom="5rem">
        <Grid container item xs={4} gap={3} maxHeight={200}>
          <Grid item xs={12}>
            <LeftTitle>
              <Typography variant="h6" textAlign="center">
                Original Image
              </Typography>
            </LeftTitle>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <FillImageBox
                isLoading={isLoading}
                imageArray={playerList[0].arrayOfImages}
                showBoxLabel={false}
                customLabelText="Player 1"
                customLabelImg={labelImages[playerList[0].variant]}
                maxWidth={375}
                imgContainerDistance={-65}
                picLabel={playerList[0].inputsArray.reduce(
                  (sum, acc) => sum + " " + acc.value,
                  ""
                )}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={8} gap={3}>
          <Grid item xs={12}>
            <SpecialButton
              btnBg={SpecialBtnLgBg2}
              btnText="Who Guessed Best?"
              customStyles={{
                backgroundPosition: "center",
                width: "100%",
                "> span": {
                  width: "100%",
                  textAlign: "center",
                },
              }}
            />
          </Grid>
          <Grid container item xs={12} gap={10} justifyContent="center">
            {playerList.map((player, i) => {
              if (i === 0) return null;
              else if (i % 2 === 0) {
                return (
                  <Grid item xs={5} position="relative">
                    <ORContainer>
                      <Typography>OR</Typography>
                    </ORContainer>
                    <FillImageBox
                      isLoading={isLoading}
                      imageArray={player.arrayOfImages}
                      showBoxLabel={false}
                      customLabelText={`Player ${i + 1}`}
                      customLabelImg={labelImages[player.variant]}
                      maxWidth={375}
                      picLabel={player.inputsArray.reduce(
                        (sum, acc) => sum + " " + acc.value,
                        ""
                      )}
                    />
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={5}>
                    <FillImageBox
                      isLoading={isLoading}
                      imageArray={player.arrayOfImages}
                      showBoxLabel={false}
                      customLabelText={`Player ${i + 1}`}
                      customLabelImg={labelImages[player.variant]}
                      maxWidth={375}
                      picLabel={player.inputsArray.reduce(
                        (sum, acc) => sum + " " + acc.value,
                        ""
                      )}
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
});

export default ChooseTheBest;
