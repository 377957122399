import React, { createContext, useEffect, useState } from "react";
import io from "socket.io-client";

export const SocketContext = createContext();
export const API_SERVER_URL = process.env.REACT_APP_SERVER;

const socket = io(API_SERVER_URL);

const SocketContextProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  console.log(`isConnected>>>>>`, isConnected);
  const [lastPong, setLastPong] = useState(null);

  useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    socket.on("pong", () => {
      setLastPong(new Date().toISOString());
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("pong");
    };
  }, []);

  const sendPing = () => {
    socket.emit("ping");
  };
  //   const [state, dispatch] = useReducer(
  //     gameReducer,
  //     GAME_INITIAL_STATE,
  //     gameInitializer
  //   );

  //   useEffect(() => {
  //     // This is a side-effect and belongs in an effect
  //     localStorage.setItem(gameStorageKey, JSON.stringify(state));
  //   }, [state]);

  //   const changeGameTab = (tab) => {
  //     dispatch(storeGameData("duelTab", tab));
  //   };
  return (
    <SocketContext.Provider value={{ socket, isConnected, lastPong, sendPing }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContextProvider;
