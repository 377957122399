import { useIsAuthenticated } from "@azure/msal-react";
import Box from "@mui/material/Box";
import { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../Context/AuthContextProvider";
import "../styles/navbar.css";
import Logo from "./../images/logo_ai2.jpg";
import UserAvatar from "./UserAvatar";

function Navbar() {
  const { handleUserLogout, handleLogin } = useContext(AuthContext);
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  // const { instance } = useMsal();

  // const handleLogin = () => {
  //   instance.loginRedirect(loginRequest).catch((e) => {
  //     console.log("LOGIN ERROR: ", e);
  //   });
  // };

  // const handleUserLogout = () => {
  //   instance
  //     .logoutPopup({
  //       postLogoutRedirectUri: "/",
  //       mainWindowRedirectUri: "/",
  //     })
  //     .then(() => {
  //       authDispatch(storeAuthData("user", {}));
  //     });
  // };

  useEffect(() => {
    window.onscroll = function () {
      if (window.pageYOffset > 300) {
        document.getElementById("navbar").style.borderBottom =
          "2px solid white";
        document.getElementById("navbar").style.transition =
          "all 0.4s ease-in-out 0.2s";
      } else {
        document.getElementById("navbar").style.borderBottom = "none";
      }
    };
  }, []);

  if (location.pathname === "/embed") return;

  return (
    <>
      <nav className="nav-wrap" id="navbar">
        <Box className="nav">
          <Link to="/">
            <img src={Logo} alt="logo-img" className="logo" />
          </Link>
          <div className="menu">
            <a
              href="https://aicharades-6279.myshopify.com/cart/44188148039990:1?channel=buy_button"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn-yellow">Buy Now</button>
            </a>
            <a
              href="https://www.aicharades.com"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn-outline">Home</button>
            </a>
            {isAuthenticated ? (
              <button
                className="btn-logout login_button"
                onClick={handleUserLogout}
              >
                <UserAvatar />
                <span className="main">Logout</span>
                <span className="res">Log out</span>
              </button>
            ) : (
              <button className="btn-outline" onClick={handleLogin}>
                Login
              </button>
            )}
          </div>
        </Box>
      </nav>
      <div className="margin-bottom-150"></div>
    </>
  );
}

export default Navbar;
