import { useMsal } from "@azure/msal-react";
import React, { useCallback, useEffect } from "react";
import { loginRequest } from "./authConfig";
import { callMsGraph } from "./graph";

const Context = React.createContext({ currentUser: null });

const ContextProvider = ({ children }) => {
  const [bottomSheetVisible, setBottomSheetVisible] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [userLoader, setUserLoader] = React.useState(false);

  const { instance, accounts } = useMsal();

  const handleRequestProfileData = useCallback(() => {
    if (accounts.length) {
      setUserLoader(true);
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => callMsGraph(response.accessToken))
        .then((response) => {
          setCurrentUser(response);
          setUserLoader(false);
        });
    }
  }, [instance, accounts, setCurrentUser]);

  useEffect(() => {
    handleRequestProfileData();
  }, [handleRequestProfileData]);

  return (
    <Context.Provider
      value={{
        bottomSheetVisible,
        setBottomSheetVisible,
        currentUser,
        setCurrentUser,
        userLoader,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
