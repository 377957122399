import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import React, { forwardRef } from "react";

// Components
import Step from "../../components/Step";
import TypeSelect from "../../components/TypeSelect/TypeSelect";
import { formTypes_telephone } from "../../data/formTypes";

const DrawCard = forwardRef((props, ref) => {
  const {
    setSelectedFormType,
    selectedFormType,
    btnText,
    disable,
    // btnBg,
    // btnIcon,
    // isLoading,
    handleOnSubmit,
  } = props;

  return (
    <Slide ref={ref} direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid item xs={12}>
        <Step stepName="Step 1: Draw a Card" />
        <Box className="inputSelect" margin="0 auto">
          <TypeSelect
            onChange={(e) => {
              setSelectedFormType(e.target.value);
            }}
            value={selectedFormType}
            formData={formTypes_telephone}
          />
        </Box>
        {/*  style={{ minWidth: "400px" }} */}
        <div className="gamePage_startGame_start">
          <button
            className="btn"
            type="button"
            onClick={handleOnSubmit}
            // style={{ minWidth: "350px" }}
            disabled={disable}
          >
            {btnText}
          </button>
        </div>
        {/* <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginTop="1.5rem"
        >
          <SpecialButton
            btnBg={btnBg}
            btnText={btnText}
            btnIcon={btnIcon}
            onClick={handleOnSubmit}
            disabled={isLoading}
            loading={isLoading}
          />
        </Box> */}
      </Grid>
    </Slide>
  );
});

export default DrawCard;
