import { BrowserRouter, Route, Routes } from "react-router-dom";

// import Login from "./pages/login";

// import bgImage from "./images/darkbg.png";
// import { styled } from "@mui/material";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import AuthContextProvider from "./Context/AuthContextProvider";
import GameContextProvider from "./Context/GameContextProvider";
import SocketContextProvider from "./Context/ScocketContextProvider";
import { protectedRoute, routes } from "./routes";
import AuthMiddleware from "./Routes/Middleware/AuthMiddleware";
/* do not edit index.js, edit App.js instead */
/* stay in functional based react, can use react hooks in functional */

// const RootSection = styled("div")(({ theme }) => ({
//   background: `url(${bgImage}) repeat center center`,
//   height: "100%",
//   width: "100%",
//   backgroundSize: "cover !important",
// }));

// Do not add a header to the /embed route

function App() {
  const windowLocation = window.location.pathname?.split("/");
  // console.log(`windowLocation`, windowLocation);
  const navRoutes = routes.filter((route) => route.navbar),
    headerRoutes = routes.filter((route) => route.header),
    footerRoutes = routes.filter((route) => route.footer),
    navAvailable = navRoutes.find(
      (route) =>
        route.path === window.location.pathname ||
        route.path?.split("/")?.[1] === windowLocation?.[1]
    ),
    headerAvailable = headerRoutes.find(
      (route) =>
        route.path === window.location.pathname ||
        route.path?.split("/")?.[1] === windowLocation?.[1]
    ),
    footerAvailable = footerRoutes.find(
      (route) =>
        route.path === window.location.pathname ||
        route.path?.split("/")?.[1] === windowLocation?.[1]
    );
  // console.log(
  //   `navAvailable`,
  //   navAvailable,
  //   footerAvailable,
  //   headerRoutes,
  //   window.location.pathname
  // );
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <SocketContextProvider>
          <GameContextProvider>
            {/* <ContextProvider> */}
            {navAvailable && <Navbar />}
            {headerAvailable && <Header />}
            <Routes>
              {routes.map((route) => (
                <Route
                  path={route.path}
                  element={route.element}
                  key={route.path}
                />
              ))}
              {protectedRoute?.map((route) => (
                <Route
                  path={route.path}
                  element={
                    <AuthMiddleware>
                      <route.element />
                    </AuthMiddleware>
                  }
                  key={route.path}
                />
              ))}
            </Routes>
            {footerAvailable && <Footer />}
            {/* </ContextProvider> */}
          </GameContextProvider>
        </SocketContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
