import React, { useState } from "react";

import ImageForm from "../components/Form";
import Image from "../components/Image";

const Prompt = () => {
  const [imageArray, setImageArray] = useState([]);
  const [loading, setLoading] = useState(false);

  return (
    <div className="prompt-container">
      <div className="prompt-div">
        <ImageForm
          imageArray={imageArray}
          apiPath="/prompt"
          setLoading={setLoading}
          setImageArray={setImageArray}
          placeholder="Describe your image"
          typeForm={"prompt"}
        />
        {loading && <p>Loading...</p>}

        {!loading &&
          imageArray.map((element, index) => (
            <Image key={index} src={element.url} name={element.prompt} />
          ))}
      </div>
    </div>
  );
};

export default Prompt;
