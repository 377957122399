import React from "react";

import { Container } from "@mui/material";


const TermsOfUse = () => {
  return (
    <div style={{ minHeight: "100vh", padding: "20px 0px", color: "white"}}>
      <Container maxWidth="xl">
        <h1>Terms of Use</h1>
        <h2 style={{marginTop: "20px"}}>Data Usage</h2>
        <p>
        By accessing the AI Charades website, you agree to be bound by these terms of service, 
        all applicable laws and regulations, and agree that you are responsible 
        for complying with them.
        If you disagree, you are prohibited from accessing the game.
        The materials contained in AI Charades are protected by applicable copyright and trademark.
        We reserve the right to change the Terms and Conditions and Privacy Policies at any time.
        Your continued use of the game after posting changes to this policy will be considered as your acceptance of those changes.
        </p>
        <h2 style={{marginTop: "20px"}}>License</h2>
        <p>
        Permission is granted to temporarily download a copy of the materials from AI Charades for personal non-commercial transient viewing. This is the granting of a license, not a transfer of title, and under this license, you cannot:
        <br /><br />
        - Try to decompile or reverse engineer any software contained in the AI Charades game;
        <br /><br />
        - Remove any copyright or other proprietary notations from the materials; or
        <br /><br />
        - Transfer the materials to another person or "mirror" the materials on any other server.
        <br /><br />
        This license must automatically terminate if you violate any of these restrictions. Therefore, you can be banned from the website at any time. When you finish viewing these materials or terminate this license, you must destroy all materials in your possession, whether in electronic or printed format.
        </p>
        <h2 style={{marginTop: "20px"}}>Limitations</h2>
        <p>
        In no circumstance shall AI Charades or its suppliers be liable for any damages. Even if the Gartic Phone or an authorized representative of the game has been notified orally or in writing about the possibility of such damage. Including, but not limited to:
        <br /><br />
        - damage due to loss of data or profit;
        <br /><br />
        - damage due to the interruption of temporary or permanent maintenance of the services, or due to the technical problems;
        <br /><br />
        - damage resulting from the use or inability to use the materials in our game.
        <br /><br />
        We do not accept any responsibility for external links sent to third parties. The inclusion of these links does not mean that we approve the material or content of these websites. We are not responsible for any loss, damage, or inconvenience you may experience while playing AI Charades.
        </p>
        <h2 style={{marginTop: "20px"}}>Applicable Law</h2>
        <p>
        These terms and conditions are governed and interpreted following United States's laws, and you are irrevocably subject to the exclusive jurisdiction of the courts in that State or location.     
        </p>
        <h2 style={{marginTop: "20px"}}>User Conduct</h2>
        <p>
        You must treat all users with respect and accomplish all updated terms, conditions, and policies connected to the AI Charades. Respect other users of the game. Inappropriate language and inappropriate behavior are prohibited. We are not responsible for user-generated content such as nicknames, phrases, and drawings. Users are responsible for their actions and any consequences that may result from there.     
        </p>
        <h2 style={{marginTop: "20px"}}>Restrictions</h2>
        <p>
        You may not use the Services in a way that infringes, misappropriates or violates any persons rights; reverse assemble, reverse compile, decompile, translate or otherwise attempt to discover the source code or underlying components of models, algorithms, and systems of the Services except to the extent such restrictions are contrary to applicable law; use the Services to develop foundation models or other large scale models; use any method to extract data from the Services, including web scraping, web harvesting, or web data extraction methods, other than as permitted through the API; represent that output from the Services was human-generated when it is not. You may use Services only in geographies currently supported by AI Charades.     
        </p>
      </Container>
    </div>
  );
};

export default TermsOfUse;
