import { Box, MenuItem, Select, styled } from "@mui/material";
import React from "react";
import { formTypes } from "../../data/formTypes";

const SelectBox = styled(Box)(({ theme }) => ({
  color: "#fff",
  "& .MuiInputBase-root": {
    display: "block",
    color: "#fff",
    border: "1px dashed #4D4D4D",
    borderRadius: "10px",
    outline: "none",
    boxShadow: "none",
    background: "#131313",
    "& svg": {
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
  },
}));

function TypeSelect({ value, onChange, formData = [] }) {
  const data = formData || formTypes;
  return (
    <SelectBox>
      <Select
        labelId="form-label"
        id="form-label"
        value={value}
        label="Select Card"
        onChange={onChange}
      >
        {data.map((formType, index) => {
          return (
            <MenuItem key={index} value={formType.value}>
              {formType.label}
            </MenuItem>
          );
        })}
      </Select>
    </SelectBox>
  );
}

export default TypeSelect;
