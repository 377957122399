import { Box, Container, Skeleton, styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import LayoutPromptForm from "../components/Form/LayoutPromptForm";
import Image from "../components/Image";
import Step from "../components/Step";
import { formTypes } from "../data/formTypes";
import { stringToArray } from "./../utils/utils";

// MainDiv is the main container for the page
const MainDiv = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(10),
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(5),
  },
}));

// FillImageBox is the container for the image
const FillImageBox = styled(Box)(({ theme }) => ({
  aspectRatio: "1/1",
  background: "#000",
  borderRadius: "10px",
  border: "3px solid #fff",
  color: "#979797",
  overflow: "hidden",
  maxHeight: "512px",
  // maxWidth: "320px",
  // margin: "0 auto",
  // height: "360px",
}));

// LayoutPrompt is the main component for the page
const LayoutPrompt = () => {
  // imageArray is the array of images generated by the AI
  const [imageArray, setImageArray] = useState([]);

  // loading is the state of the loading animation
  const [loading, setLoading] = useState(false);

  // selectedFormType is the index of the selected form type
  const [selectedFormType, setSelectedFormType] = useState(0);

  //  inputs is the array of inputs for the form
  const [inputs, setInputs] = useState([]);

  // When the selectedFormType changes, update the inputs
  useEffect(() => {
    if (selectedFormType > -1) {
      setInputs(stringToArray(formTypes[selectedFormType].prompt));
    }
  }, [selectedFormType]);

  // When the inputs change, update the imageArray
  return (
    <MainDiv>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <LayoutPromptForm
            setImageArray={setImageArray}
            setInputs={setInputs}
            setSelectedFormType={setSelectedFormType}
            setLoading={setLoading}
            inputs={inputs}
            selectedFormType={selectedFormType}
          />
          <Grid item xs={12} md={4}>
            <Step stepName={"Step 3"} subText={"AI Generated Image"} />

            <Box textAlign={"center"}>
              {/* {loading && (
              <Skeleton variant="rectangular" width={210} height={118} />
            )} */}
              {/* {loading && <p>Loading...</p>} */}

              <FillImageBox>
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{ background: "#fff", height: "100%" }}
                  />
                ) : (
                  imageArray.map((element, index) => (
                    <Image
                      key={index}
                      src={element.url}
                      name={element.prompt}
                    />
                  ))
                )}
                {/* <Image
                      src={Pic}
                    /> */}
              </FillImageBox>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </MainDiv>
  );
};

export default LayoutPrompt;
