import React from "react";

import { Container, Grid, Paper, Link, styled } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const StyledPaper = styled(Paper)({
  padding: "30px",
  textAlign: "left",
  color: "black",
  fontSize: "18px",
});

const StyledLink = styled(Link)({
  color: "black",
  textDecoration: "underline",
});

const About = () => {

  return (
    <div style={{ minHeight: "100vh", padding: "20px 0px" }}>
      <Container maxWidth="sm">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              elevation={3}
              style={{
                border: "2px solid grey",
                padding: "30px",
                textAlign: "center",
                backgroundColor: "black",
              }}
            >
              <h1 style={{ color: "white" }}>About Us</h1>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <StyledPaper elevation={3}>
            Welcome to AI Charades, a new twist on traditional party games.
                <br />
                <br />
                A group of my buddies and I had a blast
                experimenting with games that use AI. We decided to make our own, and
                AI Charades was born.
                <br />
                <br />
                In our original version of AI Charades, one player, the Creator, chooses a card with blank
                spaces for nouns, verbs, or adjectives and asks the other players to
                fill in the blanks. Once the final prompt is inputted into the AI, it
                generates an image based on the description. The other players then
                try to guess the original prompt based on the image. Points are
                awarded for correct guesses, and the game is sure to keep you and your
                friends entertained for hours on end.
                <br />
                <br />
                If you're thinking "Why not just play regular charades? It's not like
                the AI is going to act it out better than us". Trust us, it's a whole
                new level of fun and hilarity with AI Charades!
                <br />
                <br />
                We're constantly working on making AI Charades better, so if you have
                any feedback or suggestions, feel free to reach out to us at&nbsp;    
                <StyledLink component={RouterLink} to="mailto:hi@aicharades.com">hi@aicharades.com</StyledLink>. 
                <br />
                <br />
                We're always happy to hear from our players and
                make improvements based on your feedback.
            </StyledPaper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default About;