import React from "react";

const AuthTab = ({ duelTab, changeGameTab }) => {
  return (
    <div className="gamePage_authCard_header">
      <button
        className={`gamePage_authCard_header_button ${
          duelTab === "anonymous" ? "" : "active_right"
        } `}
        onClick={() => changeGameTab("anonymous")}
      >
        Anonymous
      </button>
      <button
        className={`gamePage_authCard_header_button ${
          duelTab === "authenticated" ? "" : "active_left"
        } `}
        onClick={() => changeGameTab("authenticated")}
      >
        Authenticated
      </button>
    </div>
  );
};

export default AuthTab;
