import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ImageBox from "../../../components/Duel/ImageBox";
import aiImage from "../../../images/image 1.png";

// Components
import { GameContext } from "../../../Context/GameContextProvider";
import { SocketContext } from "../../../Context/ScocketContextProvider";
import { resetGame } from "../../../Store/game/actions";
// import { ReactComponent as ImageConnector } from "./../../../images/image-connector.svg";

const Winner = (props) => {
  //   const { gameCode } = useParams();
  // const location = useLocation();
  // console.log(`🦄 ~ file: Winner.js:17 ~ Winner ~ location:`, location);
  const navigate = useNavigate();
  const { socket } = useContext(SocketContext);
  // console.log(`counter`, counter);
  // const { btnText, btnBg } = props;

  const {
    gameState: {
      // playerImage,
      //   players,
      gameData,
      joining,
      winner,
      winnerData,
      winnerType,
      // selectedFormType,
    },
    gameDispatch,
  } = useContext(GameContext);

  const message = winnerData?.data?.split("Why I Love the Winning Prompt:");
  const win = message?.[1]?.split("Why I Hate the Losing Prompts:");

  console.log(`winnerData:`, win?.[0], win?.[1]);
  useEffect(() => {
    if (!gameData?.code) {
      navigate("/duel");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.on(`Start game again ${gameData?.code}`, () => {
      gameDispatch(resetGame(navigate));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const findTopicName = useMemo(
  //   () => formTypes_telephone?.find((el) => el?.value === selectedFormType),
  //   [selectedFormType]
  // );
  // console.log(`findTopicName:`, findTopicName);

  // const findWordAddBracket = (topic, winnerText) => {
  //   let text = winnerText;
  //   const matchWord = topic?.match(/\((.*?)\)/gi) || [];
  //   let exactWord = winnerText.match(/\((.*?)\)/gi) || [];
  //   matchWord.forEach((el, idx) => {
  //     text = winnerText?.replace()
  //   })
  // };

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <div
            className="gamePage_startGame_start"
            style={{ marginTop: "5px" }}
          >
            <button
              className="btn generate_ai"
              type="button"
              style={{ background: "red" }}
            >
              Player {winner?.player_Serial}
            </button>
            <span
              style={{ color: "white", fontSize: "25px", marginLeft: "10px" }}
            >
              Wins!
            </span>
            {/* <button
              className="btn generate_ai"
              type="button"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                socket.emit("start game again");
                gameDispatch(resetGame(navigate));
              }}
            >
              Start New
            </button> */}
          </div>
        </Box>
        <div className="winnerDisplay">
          <Box
            // style={{ minWidth: winnerType === "ai" ? "47%" : "90%" }}
            className={winnerType === "ai" ? "aiwinner" : "winnerImage"}
            display="flex"
            justifyContent="center"
          >
            <ImageBox
              isLoading={false}
              imageArray={[{ url: winner?.images, prompt: "" }]}
              boxLabel={`Player ${winner?.player_Serial}`}
              joining={joining}
              player={winner}
              onClick={() => {}}
              winner
            />
          </Box>{" "}
          {winnerType === "ai" && (
            <Box className="aiText" display="flex" justifyContent="center">
              <div>
                <img src={aiImage} alt="aiImage" />
                <div className="text">
                  <h4>
                    AI Judge: I Rule in Favor of Player {winner?.player_Serial}
                  </h4>
                  <h4>Why I Love the Winning Prompt: {win?.[0]}</h4>
                  <h4>Why I Hate the Losing Prompts: {win?.[1]}</h4>
                </div>
              </div>
            </Box>
          )}
        </div>
        <div
          className="gamePage_startGame_start"
          style={{
            marginTop: "10px",
            flexDirection: "column",
            alignItems: "center",
            // marginBottom: "100px",
          }}
        >
          <div className="prompts" style={{ color: "white", margin: "10px 0" }}>
            {winner?.prompt}
          </div>
          <button
            className="btn generate_ai"
            type="button"
            style={{ marginLeft: "10px", display: "block" }}
            onClick={() => {
              socket.emit("start game again");
              gameDispatch(resetGame(navigate));
            }}
          >
            Start New
          </button>
        </div>
      </Grid>
    </Slide>
  );
};

export default Winner;
