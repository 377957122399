// import styled from "@emotion/styled";
import { Box, Container, Grid, Slide, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContextProvider";
import { GameContext } from "../../../Context/GameContextProvider";
import { SocketContext } from "../../../Context/ScocketContextProvider";
import { resetGame } from "../../../Store/game/actions";
import { joinGame, joinPlayersToGame } from "../../../Store/game/Api";

// MainDiv is the main container for the page
const MainDiv = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(5),
  },
}));

const JoinGame = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const { authState } = useContext(AuthContext);
  const {
    gameState: { joining, currentUser, loading },
    changeGameTab,
    gameDispatch,
  } = useContext(GameContext);
  const { socket } = useContext(SocketContext);
  // console.log(`currentUser`, currentUser);

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      game_id: code,
      player_type: currentUser?.playerType,
      userid: currentUser?._id,
    };
    joinPlayersToGame(gameDispatch, {
      data: body,
      url: "/duel/start-game",
      navigate,
      join: true,
      socket,
      currentUser,
    });
  };
  const reDirect = () => {
    changeGameTab("authenticated");
    navigate("/duel/join-game");
  };

  useEffect(() => {
    gameDispatch(resetGame());
    if (authState?.user?.username || authState?.user?.name) {
      reDirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.user?.username]);

  return (
    <MainDiv className="">
      <div
        className="gamePage_startGame_start d-none"
        style={{ marginTop: "5px", display: "none" }}
      >
        <button
          className="btn generate_ai"
          type="button"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            gameDispatch(resetGame(navigate));
          }}
        >
          Exit
        </button>
      </div>
      <Container maxWidth="xl">
        <Grid container>
          <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <div className="gamePage">
              <Grid item xs={12} sm="12">
                <div className="gamePage_joinGame">
                  <div className="card mb-2">
                    <button
                      onClick={() => {
                        joinGame(
                          { link: "https://www.aipuzzlebox.com" },
                          gameDispatch,
                          { navigate, currentUser, socket, startGame: true }
                        );
                        // navigate("/duel/start-game");
                      }}
                      className="btn create_game"
                      disabled={joining || loading}
                      type={"button"}
                    >
                      {joining || loading
                        ? "Joining..."
                        : "Create New Duel Game"}
                    </button>
                  </div>
                  <div className="card">
                    <form onSubmit={handleSubmit}>
                      <div className="joinGame d-flex justify-content-between">
                        <input
                          placeholder="Enter Code Here "
                          required
                          type="text"
                          onChange={(e) => setCode(e.target.value)}
                        />
                        <button
                          // onClick={() => navigate("/duel/start-game")}
                          className="btn"
                          type="submit"
                          disabled={joining || loading}
                        >
                          {joining || loading ? "Joining..." : "Join Game"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Grid>
            </div>
          </Slide>
          {/* {getActiveStepComponent(activeStep)} */}
        </Grid>
      </Container>
    </MainDiv>
  );
};

export default JoinGame;
