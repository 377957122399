import { graphConfig, loginRequest } from "../../authConfig";
import { axiosApi, get } from "../../Helpers/api_helper";
import {
  LOAD_USER,
  LOAD_USER_FAILED,
  LOAD_USER_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  STORE_AUTH_DATA,
} from "./actionTypes";

export const loginUser = () => ({
  type: LOGIN_USER,
});

export const loginUserSuccess = (data) => ({
  type: LOGIN_USER_SUCCESS,
  payload: { data },
});

export const loginUserFailed = (data) => ({
  type: LOGIN_USER_FAILED,
  payload: { data },
});
// LOGOUT

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const loadUser = () => ({
  type: LOAD_USER,
});

export const loadUserSuccess = (data, token) => ({
  type: LOAD_USER_SUCCESS,
  payload: { data, token },
});

export const loadUserFailed = (data) => ({
  type: LOAD_USER_FAILED,
  payload: { data },
});

export const storeAuthData = (name, data) => ({
  type: STORE_AUTH_DATA,
  payload: { name, data },
});

// api handler
export const loadProfileData = async ({ instance, accounts, dispatch }) => {
  try {
    dispatch(loadUser());
    // instance call for get token
    const instanceResponse = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts?.[0],
    });
    const token = instanceResponse?.accessToken;
    axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // get user by token
    const getUser = await get(graphConfig.graphMeEndpoint);
    dispatch(loadUserSuccess(getUser, token));
  } catch (err) {
    const message = err.response?.data?.message || "Load profile data failed";
    dispatch(loadUserFailed(message));
  }
};
