import React from "react";

import { Container } from "@mui/material";


const Privacy = () => {
  return (
    <div style={{ minHeight: "100vh", padding: "20px 0px", color: "white"}}>
      <Container maxWidth="xl">
        <h1>Privacy</h1>
        <br /><br />
        <p>
        AI Charades is serious about protecting the privacy of its users. This Privacy Policy sets forth how AI Charades, its related companies and affiliates, collectively, “AI Charades,” “we,” or “us”, will collect, use and share information in connection with its games, products, services and websites accessed through internet-capable platforms, including game consoles, personal computers, mobile devices, and in-game applications and software hosted by third-parties, collectively, the “Service”.
        <br /><br />
        By accessing or using the Service, you agree to this Privacy Policy and our Terms of Service and consent to our collection, use and sharing of your information as described below. Please note that this Privacy Policy applies only to information collected online through the Service, and does not apply to information that may be collected by us offline, such as, at in-person events.
        </p>

        <h2 style={{marginTop: "20px"}}>Information We Collect</h2>
        <p>
        We collect “Personal Information” in a number of ways when you visit or use the Service. Personal Information is generally defined as information about you that can be used to contact or identify you, such as your name, e-mail address, home address and telephone number. Additionally, we may also collect demographic information, such as your age, gender, country and zip code of residence, other geo-locational information, recent game purchases, and game ownership and preferences. This demographic information may be associated with your Personal Information. If this demographic information is connected with Personal Information, we will treat the demographic information as Personal Information. Personal Information does not include aggregate information, which is data we collect about the characteristics and activities of users of the Service from which any Personal Information has been removed.    
        <br /><br />
        Information You Provide To Us Directly.  When you interact with the Service, we may collect, store, and use information that you voluntarily provide to us. For example, if you register with the Service or create an account with us, you may be required to provide certain identifying information, such as your name, address, age, e-mail address, a password and other information that helps us confirm that it is you accessing your account. In addition, you may voluntarily submit Personal Information when you make a purchase, sign up for newsletters, send us feedback, post on our Facebook, Instagram, or TikTok fan pages or submit User Generated Content, as defined in our Terms of Service. We may also offer you the ability to import your address book contacts or manually enter e-mail addresses so that you can locate your contacts on the Service and invite those contacts to join you on the Service.
        <br /><br />
        Information Received From a Third Party.  When you use or interact with the Service through a social network, e.g., Facebook, or other third party platform, game console, app, or service, “Third Party Service”, you are authorizing AI Charades to collect, store, and use, in accordance with this Privacy Policy, any and all information that you agreed the Third Party Service could provide to AI Charades based on your privacy settings with the Third Party Service. This information may include Personal Information, such as, your name, user ID, profile picture, and user IDs of your friends. We may also obtain Personal Information about you from other sources, such as, our vendors, partners, and network advertising companies, and combine that information with information we have collected from or about you.
        <br /><br />
        Information Collected From the AI Charades TV Controller.  Many of our games allow users to interact with the Service through the use of a web-based controller page which is accessed by visiting the aicharades.tv URL, or other customized URL that redirects to aicharades.tv, on the user’s personal computing device, the “Controller Website”. Users may be asked to provide Personal Information, such as their name, email and phone number, to access and use the Service via the Controller Website. AI Charades also uses cookies, pixels and other tracking technologies on the Controller Website as provided below in the “Technical Usage and Cookies” section of this Privacy Policy. In some cases, AI Charades may share information collected from the Controller Website, including Personal Information, with its partner companies. For example, AI Charades may partner with a company to provide a custom or co-branded version of an AI Charades game that requires users to provide certain Personal Information on the Controller Website. AI Charades may share that information with the partner company so that they may contact users for their own marketing purposes. If you no longer wish to receive such marketing communications, please contact the partner company with your request.
        <br /><br />
        Information About Your Play and Interaction With Other Players.  We may collect information about how you use the Service and interact with other users of the Service, including on the Controller Website, to analyze user behavior in our games. For example, we may collect user information to determine a user’s average score, how often a user plays a particular game, activates certain features of the game, purchases premium content, and other Service-related events.
        <br /><br />
        We may also collect, store, and use information that you provide through any social networking features available through the Service. Please note that when you voluntarily disclose Personal Information on bulletin boards or in chat areas, that information can be collected and used by others and may result in unsolicited messages from other internet users. We do not bear any responsibility for any actions or policies of third parties.
        <br /><br />
        Payment Information.  We may use third parties to manage the purchase of software, merchandise, virtual currency, or virtual items offered by the Service. In that case, we do not have access to, and thus do not collect or store, your credit card details or billing information. You acknowledge and agree that AI Charades will have no liability for or control over such third party company’s use or protection of your payment information. You should check the privacy policy of the independent third party company’s website to determine how it will handle any payment information it collects from you and/or processes. Please note, however, that we may collect, store, and use non-payment information relating to your purchase history, such as the products you have purchased and the frequency of those purchases.
        <br /><br />
        Log Data and Mobile Devices. When you use the Service, our servers record information that your browser sends whenever you visit a website, “Log Data”. This Log Data may include information such as your computer’s Internet Protocol, “IP”, address, browser type or the webpage you were visiting before you came to the Service, pages of the websites involved in the Service that you visit, the time spent on those pages, information you search for, access times and dates, and other information. If you access the Service from a mobile or other device, we may collect a unique device identifier assigned to that device or other transactional information for that device, such as your device type, geo-location, and operating system. Log Data is tied to your Personal Information in certain circumstances, such as if you have linked a Third Party Service account to your AI Charades account.
        <br /><br />
        Cookies and Local Storage. We may also use cookies and other tracking technologies, including but not limited to browser cookies, pixels, beacons, and Adobe flash technology, which are comprised of small bits of data that often include an anonymous unique identifier. Websites send this data to your browser when you first request a web page and then store the data on your computer so the website can access information when you make subsequent requests for pages from that site. These technologies may be used to collect and store information about your usage of Service, such as pages you have visited, search queries you have run, and advertisements you have seen. Information contained in a cookie may also be linked to your Personal Information for purposes such as improving the quality of the Service, tailoring recommendations and ads to your interests, and making the Service easier to use. You can disable cookies at any time, although, if you do so, you may not be able to access or use the Service or certain of its features.
        <br /><br />
        For more information about how third-party cookies are used for targeted advertising and how to opt out of certain third-party advertising related cookies, you can visit www.networkadvertising.org, www.aboutads.info/choices, youradchoices.ca, or www.youronlinechoices.eu. Note that opting out does not mean you won’t see ads; rather, it means that the advertising you see may not be as relevant to you.
        <br /><br />
        AI Charades uses some or all of the following cookies on its Service, including its websites and mobile apps, for analytics and/or marketing purposes. Please note that while this list is regularly updated, we do not guarantee that it is fully up-to-date. In addition, other cookies and similar technologies may be used for other purposes and on AI Charades-branded websites hosted by third parties, such as, Shopify and Twitch.
        <br /><br />
        - Google Analytics for analytics and ad targeting. For more information about Google Analytics cookies, please see Google’s help pages and privacy policy. Google has developed the Google Analytics opt-out browser add-on; if you want to opt out of Google Analytics, you can download and install the add-on for your web browser here.    
        <br /><br />
        - Facebook for analytics and ad targeting. For more information about Facebook cookies, please see Facebook’s help pages and privacy policy.
        <br /><br />
        - Microsoft Clarity for analytics and ad targeting.
        <br /><br />
        - Tidio for analytics and ad targeting.
        <br /><br />
        Customer Support Information.  When you ask for assistance from our customer support team, either directly or through one of our service providers, we will collect and store the contact information you provide, generally your name and e-mail address, information about your game play or activity on the Service, and your user ID number. We will also store and use the correspondence and any information contained within.
        </p>

        <h2 style={{marginTop: "20px"}}>How We Use and Share Your Information</h2>
        <p>
        AI Charades does not share your Personal Information with third parties except as approved by you or as described in this Privacy Policy.
        <br /><br />
        To Provide, Manage, and Promote the Service.  We use the information we collect from and about users to provide the Service to you; to analyze and improve the Service; to maintain the integrity of the Service; to personalize your experience by delivering relevant content, advertising, and marketing messages; to allow you to comment on the Service, participate in contests, and promotions; to enable you to communicate and share with other users or services; and to provide you with customer support and respond to inquiries.
        <br /><br />
        We may combine Personal Information with other information and use this combination in an effort to improve your experience or to aggregate it with information collected from other users to improve or analyze our Service. This Privacy Policy in no way limits or restricts our collection and dissemination of aggregate information, User Generated Content, as defined in the Terms of Service, or of non-personal information.
        <br /><br />
        To Allow Third-Parties to Assist Us In Providing and Managing the Service.  We may share information, including Personal Information, with certain third party service providers, such as contractors, agents, or sponsors, who help us manage or provide the Service by, for example: developing or supporting products and features; conducting email marketing on our behalf; web-hosting; processing payments; administering message boards; fulfilling orders; processing data or statistics; and assisting with the administration, judging, and prize fulfillment aspects of contests and promotions. These outside service providers are required to protect any Personal Information entrusted to them and not use it for any other purpose than the specific services they are providing to AI Charades.
        <br /><br />
        We may also share your Personal Information with third party vendors to facilitate the creation and delivery of custom merchandise created using the Service ,e.g., print-on-demand items.
        <br /><br />
        To Facilitate Communication Between Friends and Other Players.  If you access the Service through a Third Party Service, your friends on the Third Party Service may see your name, profile, and descriptions of your game activity ,e.g., high scores and achievements. This information may also be used to provide in-game leader boards and promote in-game player achievements to you and other players. In addition, friends and other players will be able to see your game profile, which may include your name or a “game name” and your player likeness, profile photo, or other avatar representing you, which in certain cases can reveal your Third Party Service user ID. Access to your Third Party Service user ID may allow others to view the public information associated with your related Third Party Service account. Other players may also be able to send you game requests and friend requests through the related Third Party Service’s communication channels. 
        <br /><br />
        To Contact You.  We are committed to providing you with the best possible user experience and, therefore, one of the key uses of your Personal Information is to communicate with you. We may periodically contact you in connection with customer support inquiries, to alert you as to the status of your account, and to keep you informed of your in-game activity, including comments from friends and notifications about in-game status, achievements, and standings. Some messages, such as invites for friends to join you in a game, may include your name and profile photo. We may also contact you by e-mail for research purposes so that we can continually improve and update the Service based on your input.
        <br /><br />
        We may also send you communications directly or in partnership with companies other than AI Charades to provide you with special offers and other services. If you want to stop receiving promotional materials from AI Charades or its partners, follow the unsubscribe instructions at the bottom of each party’s e-mail. There are certain service notification e-mails that you may not opt-out of as long as you are registered with or subscribed to the Service, such as notifications of changes to our Service or policies.
        <br /><br />
        To Deliver Relevant Advertising.  We may use the Personal Information you provide to us, and information we collect about your use of the Service through cookies and other technologies, to assist us and our advertising partners in delivering you relevant ads when you use the Service and when you visit third party websites. AI Charades works with various third-party advertising agents that use cookies, web beacons, and similar technologies to collect information, as described in the “Cookies and Local Storage” section above.
        <br /><br />
        To Others.  Where you opt in to permit your Personal Information to be shared with a third party, such third party may provide you with marketing and promotional offers that may be of interest to you. If you wish to opt out of receiving communications directly from such third party, you can follow the opt out instructions in the emails that they send you
        <br /><br />
        Legal Matters; Safety.  We may, without your consent, access and disclose your information, any communications sent or received by you, and any other information that we may have about you or your account as may be permitted or required by law ,including, but not limited to, court order or subpoena, or to prevent or investigate suspected fraud, violations of our Terms of Service or this Privacy Policy, or activity that appears to us to be illegal or may expose us to legal liability. Additionally, we may disclose your information in situations that we believe to be emergencies involving potential threats to the physical safety of any person or property if we believe that your information in any way relates to that threat.
        <br /><br />
        Sale or Transfer of Assets.  If AI Charades sells all or part of its business or makes a sale or transfer of its assets or is otherwise involved in a merger or transfer of all or a material part of its business, AI Charades may transfer your Personal Information to the party or parties involved in the transaction as part of that transaction.
        </p>

        <h2 style={{marginTop: "20px"}}>Policy Regarding Children</h2>
        <p>
        AI Charades does not knowingly request or collect personal information from children younger than 13 years of age. If you believe that we have collected personal information from a child under 13, please contact us as set forth in “Contacting Us” below, and we will take action as necessary to securely delete such information.
        </p>

        <h2 style={{marginTop: "20px"}}>User Control and Retention of Data</h2>
        <p>
        If you have created an account with us, not through a Third Party Service, you may access and change your account information by visiting your account page and making the desired changes. To manage the information we receive about you from a Third Party Service, you will need to follow the instructions at that site for updating your information and changing your privacy settings. Once we receive your information from a Third Party Service, that information is stored and used by us in accordance with this Privacy Policy.
        <br /><br />
        If you created an account with us directly, not through a Third Party Service, you may cancel the Service and close your account at any time. If you access the Service through a Third Party Service, you may cancel the Service by removing the relevant Service application, such as The AI Charades Party Pack on Steam, from your Third Party Service account. Please note that even after you cancel the Service, copies of some information that is publicly viewable may remain viewable in circumstances where, for example, your information has been posted on leader boards, you have commented on content on the Service or if another person copied, stored, or shared your information. As a matter of clarity, deactivation of an account is not immediate.
        <br /><br />
        If you deactivate your account or remove the Service, we may still retain certain information associated with your account for analytical, gameplay, and recordkeeping purposes and integrity, as well as to prevent fraud, resolve disputes, enforce our Terms of Service or other policies, take actions we deem necessary due to technical and legal requirements and constraints related to the security, integrity and operation of the Service. In addition, if certain information has already been provided to third parties as described in this Privacy Policy, retention of that information will be subject to those third parties policies.
        </p>

        <h2 style={{marginTop: "20px"}}>Security</h2>
        <p>
        We have put in place reasonable technical and organizational measures designed to secure your Personal Information from accidental loss and from unauthorized access, use, alteration, or disclosure. However, we do not guarantee, and explicitly disclaim any implied representation, warranty, or other promise, that unauthorized third parties will never be able to overcome those measures or use your Personal Information for improper purposes. Also please note that e-mail and messaging systems are not considered secure, so we discourage you from sending us Personal Information through these mediums.
        </p>

        <h2 style={{marginTop: "20px"}}>Third Party Websites</h2>
        <p>
        This Privacy Policy does not apply to third party websites, online properties, or systems to which our Service may link or through which our Service may be accessed, and you accept that AI Charades is not responsible for the security or privacy practices of any such third party websites, properties or systems. In some instances, we may direct you to a site that is operated by a vendor or partner. Even though the page where you provide information may have the look and feel of the Service, a different privacy policy may govern that information collection by our vendor or partner.  
        </p>

        <h2 style={{marginTop: "20px"}}>International Transfers</h2>
        <p>
        If you are using the Service from outside the United States, by providing your information on the Service, you are consenting to the transfer of your information to the United States for processing, storage and maintenance in accordance with this Privacy Policy and our Terms of Service Agreement. We process and store your information in the United States and your information may be stored on servers located outside your residential jurisdiction. If you access the Service from outside the United States, your usage of the Service constitutes consent to the transfer of your data outside of your country and to the United States.
        </p>

        <h2 style={{marginTop: "20px"}}>California Consumer Privacy Act, CCPA</h2>
        <p>
        Under California law, California residents who have an established business relationship with us may choose to opt out of the disclosure of personal information about them to third parties for such third parties’ direct marketing purposes. If you choose to opt out at any time after granting approval, please email privacy@aicharades.com.
        <br /><br />
        Below are the categories of personal information we may have collected in connection with your use of the Service within the last twelve months:
        <br /><br />
        - Includes direct identifiers such as name address, zip code, device id, IP address, third party platform identifiers and account details ,e.g., PlayStation, Xbox, Steam, Facebook, and other online or unique identifiers.
        <br /><br />
        - Customer Records. Includes your account and profile information and customer records that contain personal information, such as username, name, demographics and other characteristics or descriptions, email, address, telephone number, and other contact information.
        <br /><br />
        - Commercial Information. Includes records of personal property, products or services purchased, obtained, or considered, or other purchasing or use histories or tendencies.
        <br /><br />
        - Usage Data. Includes browsing history, clickstream data, search history, access logs, information regarding your interactions with our websites, mobile apps and other Services and our marketing emails and online ads, and other and other usage data related to your use of the Services.
        <br /><br />
        - Geolocation Data. Includes precise location information about a particular individual or device, such as device location information for mobile games users.
        <br /><br />
        - Audio, Video and Electronic Data. Includes audio, electronic, visual, thermal, or similar information, photographs and images, e.g., that you provide us or post to your profile, call recordings, e.g., of customer support calls, and User photos submitted, such as profile images.
        <br /><br />
        - Includes inferences drawn from other personal information that we collect.
        <br /><br />
        We have obtained these categories of personal information from the sources listed above in Section 1 of this Privacy Policy.
        <br /><br />
        While we do not disclose personal information to third parties in exchange for monetary compensation from such third parties, we do disclose or make available certain categories of personal information to third parties in order to receive certain services or benefits from them, such as when we make browsing history available to third-party ad companies ,through third-party tags on our Service) in order to improve and measure our ad campaigns and reach users with more relevant ads and content.
        <br /><br />
        As a California resident, you have the right to request that we disclose certain information to you about our collection and use of your Personal Information over the past twelve months. Once we receive and confirm your verifiable consumer request, we will disclose to you:
        <br /><br />
        - The categories of personal information we collected about you.
        <br /><br />
        - The categories of sources for the personal information we collected about you.
        <br /><br />
        - Our business or commercial purpose for collecting or selling that personal information.
        <br /><br />
        - The categories of third parties with whom we share that personal information.
        <br /><br />
        - The specific pieces of personal information we collected about you.
        <br /><br />
        - You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete and direct our service providers to delete, your personal information from our records, unless a statutory exception applies. To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by emailing us at privacy@aicharades.com.
        <br /><br />
        Only you,or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal information. There are a few rules for verifiable consumer requests:
        <br /><br />
        - You may only make a verifiable consumer request twice within a 12-month period.
        <br /><br />
        - Plus, the verifiable consumer request must have enough information so we can verify that it’s really you, and describe your request with enough detail so we understand, and can properly respond to your request.
        <br /><br />
        If we can’t verify that it’s you or understand what you are requesting, we will not be able to respond to your request or provide you with personal information. We will only use personal information provided in a verifiable consumer request to verify your identity.
        <br /><br />
        We will not discriminate against you for exercising any of your CCPA rights, and you will be able to continue to access the Service. If you have any questions or comments about your choices and rights under CCPA, please email us at privacy@aicharades.com.
        <br /><br />
        Finally, California law also requires us to tell you how AI Charades responds to Do Not Track ,“DNT”, signals from your web browser. When DNT is enabled, a user’s web browser sends a signal to websites visited by the user to indicate that the user does not want to be tracked. While AI Charades honors your privacy and choices, no common standard for DNT has been adopted by the technology industry or regulators. Therefore, AI Charades does not respond to or take any action with respect to a DNT signal sent from your web browser.
        </p>

        <h2 style={{marginTop: "20px"}}>Changes to this Agreement</h2>
        <p>
        AI Charades reserves the right, in its sole discretion, to modify or revise this Privacy Policy at any time, and you agree to be bound by such modifications or revisions. Any such change or modification will be effective immediately or, where required by law, 30 days after its publication in connection with the Service. AI Charades may notify you of any such changes by providing a link to the new Privacy Policy before you use the Service, posting the notice of such changes on AI Charades’s website, or sending notice via other means. Your continued use of the Service after any changes or modifications to this Privacy Policy are posted will constitute your acceptance of, and agreement to, such changes or modifications. If you object to any change or modification, your sole recourse shall be to cease using the Service.
        </p>

        <h2 style={{marginTop: "20px"}}>Contact Us</h2>
        <p>
        If you have any questions about this Privacy Policy or the practices of the Service, please contact us at privacy@aicharades.com or write to us at: AI Puzzle Box, 3334 Fillmore Street, San Francisco, CA 94123, Attn: Customer Support
        </p>
      </Container>
    </div>
  );
};

export default Privacy;
