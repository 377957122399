import React, { useState, useRef, forwardRef } from "react";
import axios from "axios";
import { Box, Container, styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import { stringToArray } from "../../utils/utils";
import { formTypes_telephone } from "../../data/formTypes";

// Components
import AddPlayers from "./addPlayers";
import FillInTheBlanks from "./../Duel/fillInTheBlanks";
import DescribeImage from "./../../components/DescribeImage";
import WhoGuessedBest from "./whoGuessedBest";
import SpecialBtnBg1 from "./../../images/special-btn-lg-bg-1.svg";
import RedBtnSVG from "./../../images/red-btn.svg";
import BlueBtnSVG from "./../../images/blue-btn.svg";
import GreenBtnSVG from "./../../images/green-btn.svg";
import PurpleBtnSVG from "./../../images/purple-btn.svg";
import YellowBtn2SVG from "./../../images/yellow-btn-2.svg";
import OrangeBtnSVG from "./../../images/orange-btn.svg";

// MainDiv is the main container for the page
const MainDiv = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(5),
  },
}));

const PlayerOneBlanks = forwardRef((props, ref) => (
  <FillInTheBlanks ref={ref} {...props} />
));

const createDescribeImageComponent = () => {
  return forwardRef((props, ref) => <DescribeImage ref={ref} {...props} />);
};

const initialPlayers = () => [
  {
    inputsArray: stringToArray(formTypes_telephone[53].prompt),
    arrayOfImages: [],
    variant: "primary",
  },
  {
    inputsArray: [
      {
        name: "fictional character",
        value: "",
        inputIndex: 0,
      },
    ],
    arrayOfImages: [],
    variant: "danger",
    component: createDescribeImageComponent(),
  },
];

const getPromise = (result) => {
  return axios.post(`${process.env.REACT_APP_SERVER}/prompt`, {
    test: result,
  });
};

const labelImages = {
  primary: BlueBtnSVG,
  danger: RedBtnSVG,
  success: GreenBtnSVG,
  purpleBtn: PurpleBtnSVG,
  yellowBtn: YellowBtn2SVG,
  orangeBtn: OrangeBtnSVG,
};

export default function Telephone() {
  // players
  const [playerList, updatePlayerList] = useState(initialPlayers());
  const [playerVariants, updatePlayerVariants] = useState([
    "danger",
    "primary",
    "yellowBtn",
    "purpleBtn",
    "orangeBtn",
    "success",
  ]);

  // Active Step
  const [activeStep, setActiveStep] = useState("add-players");

  // loading is the state of the loading animation
  const [isLoading, setIsLoading] = useState(0);

  const addPlayersRef = useRef();
  const fbp1Ref = useRef();
  const descImageRef = useRef();
  const wgbRef = useRef();

  const handleGetImageArray = async (value, index, nextStep) => {
    const updatedPlayersList = [...playerList];
    // Fill in the blanks in the form string using the form inputs
    setIsLoading(index + 1);
    try {
      let response = null;
      if (index > 0) {
        response = await getPromise(value[0].value);
      } else {
        let result1 = formTypes_telephone[53].prompt;
        value.forEach((input) => {
          result1 = result1.replace(`(${input.name})`, input.value);
        });
        response = await getPromise(result1);
      }
      if (response.data.arrayOfImages[0].prompt === "Please try again") {
        setIsLoading(0);
      } else {
        updatedPlayersList[index].arrayOfImages = response.data.arrayOfImages;
        updatePlayerList(updatedPlayersList);
        setIsLoading(0);
        setActiveStep(nextStep);
      }
    } catch (error) {
      setIsLoading(0);
      console.log("Get Images Error: ", error);
    }
  };

  const handlePlayerInput = (value, index) => {
    const updatedPlayersList = [...playerList];
    updatedPlayersList[index].inputsArray = value;
    updatePlayerList(updatedPlayersList);
  };

  const getActiveStepComponent = (key) => {
    if (key === "add-players") {
      return (
        <AddPlayers
          ref={addPlayersRef}
          playerList={playerList}
          playerVariants={playerVariants}
          updatePlayerVariants={updatePlayerVariants}
          updatePlayerList={updatePlayerList}
          handleOnSubmit={() => {
            setActiveStep("fbp1");
          }}
        />
      );
    } else if (key === "fbp1") {
      return (
        <PlayerOneBlanks
          ref={fbp1Ref}
          stepName="Player 1: Fill in the blanks!"
          selectedFormType={53}
          inputs={playerList[0].inputsArray}
          setInputs={(value) => handlePlayerInput(value, 0)}
          handleOnSubmit={() => {
            handleGetImageArray(playerList[0].inputsArray, 0, "di_1");
          }}
          btnText={
            isLoading === 1
              ? "please wait, generating the image"
              : "Submit and Pass to Player 2 to Guess"
          }
          btnBg={SpecialBtnBg1}
          stepVariant="primary"
          isLoading={!!isLoading}
        />
      );
    } else if (key === "wgb") {
      return (
        <WhoGuessedBest
          ref={wgbRef}
          playerList={playerList}
          isLoading={!!isLoading}
        />
      );
    } else {
      const currentUser = parseInt(activeStep.split("_")[1]);
      const areMorePlayers = playerList.length > currentUser + 1;
      const NewDescribeImage = playerList[currentUser].component;
      const stepVariant = playerList[currentUser].variant;

      return (
        <NewDescribeImage
          ref={(ref) => (descImageRef.current = ref)}
          stepName={`Player ${currentUser + 1}: Describe the Image`}
          prevPlayerArrayOfImages={playerList[currentUser - 1].arrayOfImages}
          playerInput={playerList[currentUser].inputsArray}
          updatePlayerInput={(value) => handlePlayerInput(value, currentUser)}
          handleOnClickBtn={() => {
            const nextStep = areMorePlayers ? `di_${currentUser + 1}` : "wgb";
            handleGetImageArray(
              playerList[currentUser].inputsArray,
              currentUser,
              nextStep
            );
          }}
          btnText={
            isLoading >= currentUser
              ? "please wait, generating the image"
              : areMorePlayers
              ? `Submit Guess and Pass to Player ${currentUser + 2}`
              : "Submit Guess and See Final Results"
          }
          btnBg={SpecialBtnBg1}
          stepVariant={stepVariant}
          labelText={`Player ${currentUser + 1}`}
          labelImg={labelImages[stepVariant]}
          isLoading={!!isLoading}
        />
      );
    }
  };

  return (
    <MainDiv>
      <Container maxWidth="xl">
        <Grid container>{getActiveStepComponent(activeStep)}</Grid>
      </Container>
    </MainDiv>
  );
}
