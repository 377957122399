import { Box, Skeleton, styled } from "@mui/material";
import React, { useContext } from "react";
import { GameContext } from "../../Context/GameContextProvider";

// Components
import Image from "../Image";

// ImageBox is the container for the image
const FillImageBoxContainer = styled(Box)(
  ({ maxHeight, maxWidth, background, customStyles }) => ({
    aspectRatio: "1/1",
    background: background || "#000",
    borderRadius: "15px",
    border: "2px solid #fff",
    color: "#979797",
    maxHeight: maxHeight || "475px",
    maxWidth: maxWidth || "475px",
    flex: 1,
    zIndex: "2",
    position: "relative",
    padding: "1rem",
    ...customStyles,
  })
);

export default function ImageBox({
  isLoading,
  imageArray,
  boxLabel,
  maxHeight,
  maxWidth,
  background,
  customStyles,
  onClick,
  allowClickImg,
  customSkeletonStyles,
  player,
  winner,
}) {
  const {
    gameState: { votedPlayer, joining },
    // gameDispatch,
  } = useContext(GameContext);
  // console.log(`🦄 ~ file: ImageBox.js:75 ~ imageArray`, imageArray);
  return (
    <FillImageBoxContainer
      background={background}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      customStyles={customStyles}
      onClick={onClick}
    >
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          sx={{ background: "#fff", height: "100%", ...customSkeletonStyles }}
        />
      ) : (
        imageArray.map((element, index) => (
          <Image
            key={index}
            imgIndex={index}
            src={element?.url}
            name={element?.prompt}
            allowClick={allowClickImg}
            message={element?.url ? "" : "Waiting.."}
          />
        ))
      )}
      {!winner && (
        <div className="image_vote">
          {joining
            ? "Voting..."
            : votedPlayer?.votedPlayer === player?._id
            ? "Voted"
            : "Vote"}
        </div>
      )}
      {boxLabel && !winner ? <div className="box_title">{boxLabel}</div> : null}
    </FillImageBoxContainer>
  );
}
