import { post } from "../../Helpers/api_helper";
import { storeAuthData } from "./actions";

// creating game
const createUser = async (data, dispatch) => {
  dispatch(storeAuthData("loading", true));
  try {
    const currentUser = data?.[0];
    const body = {
      name: currentUser?.name,
      userid: currentUser?.username,
      auth_type: "microsoft",
    };
    const url = "/games/user-create";
    const user = await post(url, body);
    // console.log("user>>>game", user, currentUser, socket);
    dispatch(storeAuthData("user", user?.data));
    // navigate("/duel/start-game");
  } catch (err) {
    const message = err.response?.data?.message || "Failed to load";
    dispatch(storeAuthData("error", message));
  } finally {
    dispatch(storeAuthData("loading", false));
  }
};

export { createUser };
