import React, { forwardRef } from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import { CloseRounded } from "@mui/icons-material";

// Components
import Step from "../../components/Step";
import CustomButton from "../../components/CustomButton";
import SpecialButton from "../../components/SpecialButton/SpecialButton";
import DescribeImage from "./../../components/DescribeImage";
import SpecialBtnBg from "./../../images/special-btn-bg.svg";

const createDescribeImageComponent = () => {
  return forwardRef((props, ref) => <DescribeImage ref={ref} {...props} />);
};

// Step Name
const StepName = ({ stepIndex, text, handleRemovePlayer, showRemoveIcon }) => {
  return (
    <Typography
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
    >
      <span>{text}</span>
      {showRemoveIcon && (
        <CloseRounded
          sx={{
            position: "absolute",
            right: -36,
            top: 0,
            width: "1rem",
            cursor: "pointer",
          }}
          onClick={() => handleRemovePlayer(stepIndex)}
        />
      )}
    </Typography>
  );
};

const AddPlayers = forwardRef((props, ref) => {
  const {
    playerList,
    updatePlayerList,
    handleOnSubmit,
    playerVariants,
    updatePlayerVariants,
  } = props;

  const handleAddPlayer = () => {
    const updatedPlayers = [
      ...playerList,
      {
        inputsArray: [
          { name: "fictional character", value: "", inputIndex: 0 },
        ],
        arrayOfImages: [],
        component: createDescribeImageComponent(),
        variant: playerVariants.pop(),
      },
    ];

    if (!playerVariants.length) {
      updatePlayerVariants([
        "danger",
        "primary",
        "success",
        "purpleBtn",
        "yellowBtn",
        "orangeBtn",
      ]);
    }

    updatePlayerList(updatedPlayers);
  };

  const handleRemovePlayer = (index) => {
    const updatedPlayers = [...playerList];
    updatedPlayers.splice(index, 1);
    updatePlayerList(updatedPlayers);
  };

  return (
    <Slide ref={ref} direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid item xs={12}>
        <Step stepName="Step 1: Add Players" />
        <Box
          margin="2rem auto 0 auto"
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          maxWidth="450px"
        >
          {playerList.map((player, i) => {
            if (i % 2 === 0) {
              return (
                <Step
                  variant={player.variant}
                  key={`step_name_${i}`}
                  stepName={
                    <StepName
                      stepIndex={i}
                      text={`Player ${i + 1}`}
                      showRemoveIcon={i > 1}
                      handleRemovePlayer={handleRemovePlayer}
                    />
                  }
                />
              );
            } else {
              return (
                <React.Fragment key={`step_name_${i}`}>
                  <div style={{ margin: "0 0.5rem" }} />
                  <Step
                    variant={player.variant}
                    stepName={
                      <StepName
                        stepIndex={i}
                        text={`Player ${i + 1}`}
                        showRemoveIcon={i > 1}
                        handleRemovePlayer={handleRemovePlayer}
                      />
                    }
                  />
                </React.Fragment>
              );
            }
          })}
        </Box>
        <Box
          maxWidth="325px"
          display="flex"
          justifyContent="center"
          margin="2rem auto 0 auto"
        >
          <CustomButton text="+ Add Player" onClick={handleAddPlayer} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginTop="1.5rem"
        >
          <SpecialButton
            btnBg={SpecialBtnBg}
            btnText="Submit and Pass to Player 1"
            onClick={handleOnSubmit}
          />
        </Box>
      </Grid>
    </Slide>
  );
});

export default AddPlayers;
