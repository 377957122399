import axios from "axios";
import { useState } from "react";
import GalleryForm from "./GalleryForm";
import PromptForm from "./PromptForm";
import SimplePromptForm from "./SimplePromptForm";

function ImageForm({
  setLoading,
  setImageArray,
  apiPath,
  placeholder,
  typeForm,
}) {
  const [prompt, setPrompt] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const { data } = await axios.post(`${process.env.REACT_APP_SERVER}${apiPath}`, {
      test: prompt,
    });
    setImageArray(data.arrayOfImages);
    setLoading(false);
  }

  const handleChange = (e) => {
    setPrompt(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      {typeForm === "prompt" ? (
        <PromptForm
          handleChange={handleChange}
          placeholder={placeholder}
          prompt={prompt}
        />
      ) : (
        typeForm === "builder" ? (
          <GalleryForm
            handleChange={handleChange}
            placeholder={placeholder}
            prompt={prompt}
          />
        ) : (
          typeForm === "simpleprompt" && (
            <SimplePromptForm
              handleChange={handleChange}
              placeholder={placeholder}
              prompt={prompt}
            />
          )
        )
      )}
    </form>
  );
}

export default ImageForm;
