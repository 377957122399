import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContextProvider";

const AuthMiddleware = ({ children }) => {
  const { authState } = useContext(AuthContext);
  console.log(`authState`, authState);
  // const location = useLocation();
  // if (!authState?.token) {
  //   return (
  //     <Navigate
  //       to={{
  //         pathname: "/duel",
  //         //  state: { from: location }
  //       }}
  //       replace
  //     />
  //   );
  // }
  return children;
};

export default AuthMiddleware;
