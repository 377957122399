import About from "./pages/about";
import Builder from "./pages/Builder";
import Contact from "./pages/contact";
import Duel from "./pages/Duel";
import ChooseTheBest from "./pages/Duel/Game/ChooseTheBest";
import JoinGame from "./pages/Duel/Game/JoinGame";
import PlayGround from "./pages/Duel/Game/PlayGround";
import StartGame from "./pages/Duel/Game/StartGame";
import Winner from "./pages/Duel/Game/Winner";
import EmbedPrompt from "./pages/embedPrompt";
import Gallery from "./pages/gallery";
import LayoutPrompt from "./pages/layoutPrompt";
import Privacy from "./pages/privacy";
import Prompt from "./pages/prompt";
import SimplePrompt from "./pages/simplePrompt";
import Telephone from "./pages/Telephone";
import TermsOfUse from "./pages/terms";

export const routes = [
  {
    path: "/",
    element: <LayoutPrompt />,
    navbar: true,
    header: true,
    footer: true,
  },
  {
    path: "/embed",
    element: <EmbedPrompt />,
    navbar: false,
    header: false,
    footer: false,
  },
  {
    path: "/builder",
    element: <Builder />,
    navbar: true,
    header: true,
    footer: true,
  },
  {
    path: "/telephone",
    element: <Telephone />,
    navbar: true,
    header: true,
    footer: true,
  },
  {
    path: "/prompt",
    element: <Prompt />,
    navbar: true,
    header: false,
    footer: true,
  },
  {
    path: "/simple-prompt",
    element: <SimplePrompt />,
    navbar: true,
    header: false,
    footer: true,
  },
  {
    path: "/duel",
    element: <Duel />,
    navbar: true,
    header: true,
    footer: true,
  },
  {
    path: "/gallery",
    element: <Gallery />,
    navbar: true,
    header: true,
    footer: true,
  },
  {
    path: "/freeform",
    element: <SimplePrompt />,
    navbar: true,
    header: true,
    footer: true,
  },
  {
    path: "/terms",
    element: <TermsOfUse />,
    navbar: true,
    header: false,
    footer: true,
  },
  {
    path: "/privacy",
    element: <Privacy />,
    navbar: true,
    header: false,
    footer: true,
  },
  {
    path: "/contact",
    element: <Contact />,
    navbar: true,
    header: true,
    footer: true,
  },
  {
    path: "/about",
    element: <About />,
    navbar: true,
    header: true,
    footer: true,
  },
];

export const protectedRoute = [
  {
    path: "/duel/join-game",
    element: JoinGame,
    navbar: true,
    header: true,
    footer: true,
  },
  {
    path: "/duel/start-game",
    element: StartGame,
    navbar: true,
    header: true,
    footer: true,
  },
  {
    path: "/duel/playground",
    element: PlayGround,
    navbar: true,
    header: true,
    footer: true,
  },
  {
    path: "/duel/choose-best/:gameCode",
    element: ChooseTheBest,
    navbar: true,
    header: true,
    footer: true,
  },
  {
    path: "/duel/winner/:gameCode",
    element: Winner,
    navbar: true,
    header: true,
    footer: true,
  },
];
