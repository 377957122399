const genrePrompts = [
  "modern art",
  "abstract art",
  "anime art",
  "cubism art",
  "renaissance tapestry",
];
const mediumPrompts = [
  "album cover",
  "movie poster",
  "children's book cover",
  "comic book cover",
  "magazine cover",
];
const animalPrompts = ["dog", "cat", "bird", "fish", "snake"];
const characterPrompts = [
  "superhero",
  "villain",
  "fantasy character",
  "historical figure",
  "animated character",
];
const landscapePrompts = [
  "tropical island",
  "desert landscape",
  "mountain range",
  "waterfall scenery",
  "forests",
];

const initialBuilderData = () => [
  genrePrompts,
  mediumPrompts,
  animalPrompts,
  characterPrompts,
  landscapePrompts,
];

// const builderData = [
//   genrePrompts,
//   mediumPrompts,
//   animalPrompts,
//   characterPrompts,
//   landscapePrompts,
// ];

// Dummy Images Array
const allArrays = [
  [
    {
      prompt: "modern art",
      original: "modern art",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1673893834862-modern%20art.png",
    },
    {
      prompt: "abstract art",
      original: "abstract art",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1673893834987-abstract%20art.png",
    },
    {
      prompt: "anime art",
      original: "anime art",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1673893834982-anime%20art.png",
    },
    {
      prompt: "cubism art",
      original: "cubism art",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1673893834615-cubism%20art.png",
    },
    {
      prompt: "renaissance tapestry",
      original: "renaissance tapestry",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1673893834745-renaissance%20tapestry.png",
    },
  ],
  [
    {
      prompt: "album cover",
      original: "album cover",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908764276-album%20cover.png",
    },
    {
      prompt: "movie poster",
      original: "movie poster",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908763718-movie%20poster.png",
    },
    {
      prompt: "children's book cover",
      original: "children's book cover",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908763937-children%27s%20book%20cover.png",
    },
    {
      prompt: "comic book cover",
      original: "comic book cover",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908763321-comic%20book%20cover.png",
    },
    {
      prompt: "magazine cover",
      original: "magazine cover",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908764766-magazine%20cover.png",
    },
  ],
  [
    {
      prompt: "dog",
      original: "dog",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908764190-dog.png",
    },
    {
      prompt: "cat",
      original: "cat",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908763303-cat.png",
    },
    {
      prompt: "bird",
      original: "bird",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908764031-bird.png",
    },
    {
      prompt: "fish",
      original: "fish",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908764770-fish.png",
    },
    {
      prompt: "snake",
      original: "snake",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908765279-snake.png",
    },
  ],
  [
    {
      prompt: "superhero",
      original: "superhero",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908766401-superhero.png",
    },
    {
      prompt: "villain",
      original: "villain",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908766200-villain.png",
    },
    {
      prompt: "fantasy character",
      original: "fantasy character",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908767650-fantasy%20character.png",
    },
    {
      prompt: "historical figure",
      original: "historical figure",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908765896-historical%20figure.png",
    },
    {
      prompt: "animated character",
      original: "animated character",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908765081-animated%20character.png",
    },
  ],
  [
    {
      prompt: "tropical island",
      original: "tropical island",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908767083-tropical%20island.png",
    },
    {
      prompt: "desert landscape",
      original: "desert landscape",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908766755-desert%20landscape.png",
    },
    {
      prompt: "mountain range",
      original: "mountain range",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908765988-mountain%20range.png",
    },
    {
      prompt: "waterfall scenery",
      original: "waterfall scenery",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908764852-waterfall%20scenery.png",
    },
    {
      prompt: "forests",
      original: "forests",
      url: "https://aiimagesstab.blob.core.windows.net/aiimagesstab/1674908767138-forests.png",
    },
  ],
];

export { initialBuilderData, allArrays };
