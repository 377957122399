import React from "react";

const GalleryForm = ({handleChange, prompt, placeholder}) => {
  return (
    <>
      <div className="prompt-left">
      
        <div className="prompt-step-2">
          <input
            value={prompt}
            type="text"
            placeholder={placeholder}
            onChange={handleChange}
          ></input>
        </div>
      </div>
      <div className="prompt-center">
        <button type="submit">Submit</button>
      </div>
    </>
  );
};

export default GalleryForm;
