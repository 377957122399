import { Box, Container, Slide, styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useBeforeunload } from "react-beforeunload";
import { useNavigate } from "react-router-dom";
import LeftSide from "../../../components/Duel/LeftSide";
import WaitForOthers from "../../../components/Duel/WaitForOthers";
import { GameContext } from "../../../Context/GameContextProvider";
import { SocketContext } from "../../../Context/ScocketContextProvider";
import { formTypes_telephone } from "../../../data/formTypes";
import useCountDown from "../../../Hooks/useCountDown";
import { resetGame, storeGameData } from "../../../Store/game/actions";
import {
  generateImage,
  generatePrompt,
  getPlayersInGame,
  getSingleGame,
  updateCallBack,
} from "../../../Store/game/Api";
import "../../../styles/game.css";
import { stringToArray } from "../../../utils/utils";
// Components
import SpecialBtnBg from "./../../../images/special-btn-bg.svg";
import DrawCard from "./../drawCard";
import FillInTheBlanks from "./../fillInTheBlanks";

// MainDiv is the main container for the page
const MainDiv = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(5),
  },
}));

const PlayerOneBlanks = forwardRef((props, ref) => (
  <FillInTheBlanks ref={ref} {...props} />
));

const PlayGround = () => {
  const navigate = useNavigate();
  const [tabChanging, setTabChanging] = useState(false);
  // selectedFormType is the index of the selected form type
  const {
    gameState: {
      activeStep,
      selectedFormType,
      playersInput,
      joining,
      currentUser,
      gameData,
      reRender,
      players,
      creator,
    },
    gameDispatch,
  } = useContext(GameContext);

  const { counter } = useCountDown(
    120,
    () => {
      // update data
      console.log("call>>>");
      if (joining) return;
      updateCallBack(gameDispatch, {
        socket,
        player_Serial: currentUser?.player_Serial,
        gameCode: gameData?.code,
        playersInput,
        players,
        navigate,
      });
    },
    activeStep === "player-form" && !joining
  );
  // Active Step
  // console.log(`activeStep`, activeStep, selectedFormType);

  const drawCardRef = useRef();
  const { socket } = useContext(SocketContext);
  //   console.log(`currentUser`, currentUser);

  const getPlayer = (turn, callBack) =>
    getPlayersInGame(gameDispatch, {
      game_id: gameData?.code,
      currentUser,
      newTurn: turn,
      callBack,
    });

  useEffect(() => {
    if (!gameData?.code) {
      navigate("/duel");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findTopicName = useMemo(
    () => formTypes_telephone?.find((el) => el?.value === selectedFormType),
    [selectedFormType]
  );

  const turnPlayer = useMemo(() => {
    return players?.find((el) => el?.turn) || {};
  }, [players]);

  console.log(`findTopicName`, findTopicName, selectedFormType, playersInput);

  const getGame = () =>
    getSingleGame(gameDispatch, { gameCode: gameData?.code });

  useBeforeunload((event) => {
    // if (value !== '') {
    // console.log(`event`, event);
    event.preventDefault();
    // }
  });

  useEffect(() => {
    //. new players
    socket.on("players", (data) => {
      // console.log("socket_player", data);
      getPlayer();
    });

    // .next player emit
    socket.on(
      `now turn is for another player ${gameData?.code}`,
      async (turnPlayer) => {
        // console.log("socket_turn another player", turnPlayer);
        await getPlayer("check", (players) => {
          submitForAi(players);
        });
        if (turnPlayer === currentUser?.player_Serial) {
          await gameDispatch(storeGameData("activeStep", "player-form"));
        }
        // else {
        //   await gameDispatch(storeGameData("activeStep", "wait-for-others"));
        // }
      }
    );
    // console.log(`socket`, creator?._id === currentUser?._id);

    /// game start emit /
    socket.on(`game started in room ${gameData?.code}`, async (topic) => {
      // console.log("socket_game start>>>>", topic);
      const findTopicName = formTypes_telephone?.find(
        (el) => el?.label === topic
      );
      gameDispatch(storeGameData("selectedFormType", findTopicName?.value));
      getGame();
      getPlayer();
      navigate("/duel/playground");
    });

    // end socket game
    socket.on(`end game ${gameData?.code}`, () => {
      navigate(`/duel/choose-best/${gameData?.code}`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  // console.log(`game started in room ${gameData?.code}`);

  // / submit
  const submitForAi = (players) => {
    if (creator?._id === currentUser?._id) {
      const turnPlayer = players?.find((el) => el?.turn);
      // console.log(`findData`, turnPlayer);
      if (turnPlayer?.player_type === "AI") {
        // console.log("submit>>>AI");
        generatePrompt(gameDispatch, {
          body: { card_text: findTopicName?.prompt },
          successCallBack: (text) => postImage(text, turnPlayer),
        });
      }
    }
  };

  useEffect(() => {
    getGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPlayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRender]);
  //   const fbp2Ref = useRef();

  const getActiveStepComponent = (key) => {
    switch (key) {
      case "draw-card":
        return (
          <Grid item xs={12} md="6">
            <DrawCard
              ref={drawCardRef}
              selectedFormType={selectedFormType}
              handleOnSubmit={async () => {
                setTabChanging(true);
                await socket.emit(
                  "game started",
                  gameData?.code,
                  findTopicName?.label,
                  async () => {
                    // setTimeout(())
                    await getPlayer(true);
                    await gameDispatch(
                      storeGameData("activeStep", "player-form")
                    );
                    setTabChanging(false);
                  }
                );
                // await gameDispatch(rerenderGameData());
              }}
              btnText={tabChanging ? "Starting..." : "Start"}
              disable={tabChanging}
              btnBg={SpecialBtnBg}
              stepVariant="danger"
              setSelectedFormType={(value) => {
                gameDispatch(storeGameData("selectedFormType", value));
              }}
            />
          </Grid>
        );
      case "player-form":
        return (
          <Grid item xs={12} md="6">
            <form onSubmit={(e) => e.preventDefault()}>
              <PlayerOneBlanks
                stepName={`Player ${turnPlayer?.player_Serial}: Fill in the blanks!`}
                selectedFormType={selectedFormType}
                inputs={playersInput}
                setInputs={(data) =>
                  gameDispatch(storeGameData("playersInput", data))
                }
                handleOnSubmit={handleSubmit}
                btnText="Submit and Pass to next player"
                btnBg={SpecialBtnBg}
                stepVariant="danger"
                isLoading={joining}
              />
              <button type="submit" hidden></button>
            </form>
          </Grid>
        );
      case "wait-for-others":
        return (
          <Grid item xs={12} md="8">
            <WaitForOthers turnPlayer={turnPlayer} />
          </Grid>
        );
      default:
        return null;
    }
  };

  const handleSubmit = () => {
    // console.log("submit>>>>>handle");
    let result = formTypes_telephone[selectedFormType].prompt;
    playersInput.forEach((input) => {
      result = result.replace(`(${input.name})`, input.value);
    });
    const checkData = playersInput?.every((el) => el.value);
    // generate image api call
    if (checkData) postImage(result, currentUser);
  };

  const postImage = (result, currentUser) => {
    console.log("players input", playersInput);
    generateImage(gameDispatch, {
      // image generate body
      data: { test: result },
      // update player body
      player_Serial: currentUser?.player_Serial,
      socket,
      body: {
        id: currentUser?._id,
        gameCode: gameData?.code,
      },
      playersInput,
      players,
      navigate,
    });
  };

  // When the selectedFormType changes, update the inputs
  useEffect(() => {
    if (selectedFormType > -1) {
      const data = stringToArray(formTypes_telephone[selectedFormType].prompt);
      gameDispatch(storeGameData("playersInput", data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormType]);

  return (
    <MainDiv className="">
      <div
        className="gamePage_startGame_start d-none"
        style={{ marginTop: "5px" }}
      >
        <button
          className="btn generate_ai"
          type="button"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            gameDispatch(resetGame(navigate));
          }}
        >
          Exit
        </button>
      </div>
      <div style={{ textAlign: "center" }}>
        {activeStep === "player-form" && !joining && (
          <span style={{ color: "red" }}>{counter}s</span>
        )}
      </div>
      <Container maxWidth="xl">
        {/* <button
          onClick={() => gameDispatch(storeGameData("activeStep", "draw-card"))}
        >
          clear
        </button> */}
        <Grid container className="d-flex justify-content-center playgound">
          <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <Grid item xs={12}>
              <Grid container className="d-flex">
                <Grid
                  item
                  md={4}
                  style={{ marginBottom: "20px", width: "100%" }}
                  display="flex"
                  justifyContent={"center"}
                >
                  <LeftSide></LeftSide>
                </Grid>
                {getActiveStepComponent(activeStep)}
              </Grid>
            </Grid>
          </Slide>
        </Grid>
      </Container>
    </MainDiv>
  );
};

export default PlayGround;
