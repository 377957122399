import React, { useState } from "react";

const PromptForm = () => {
  let promptTextplaceholder = "(animal) and (person) jump over a (noun)"
  
    const [formString] = useState(
        promptTextplaceholder
  );

  const [inputs, setInputs] = useState(stringToArray(formString));

  // Extract the values in paranthesis using a regular expression
  function stringToArray(str) {
    const regex = /\((.+?)\)/g;
    let match;
    const inputs = [];

    while ((match = regex.exec(str))) {
      inputs.push({
        name: match[0].replace("(", "").replace(")", ""),
        value: "",
      });
    }
    return inputs;
  }

  const handleChange = (event, index) => {
    const values = [...inputs];
    values[index].value = event.target.value;
    setInputs(values);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Fill in the blanks in the form string using the form inputs
    let result = formString;
    inputs.forEach((input) => {
      result = result.replace(`(${input.name})`, input.value);
    });
  };

  // Return a string composed from the different forms created from the initial prompt
  return (
    <div onSubmit={handleSubmit}>
      {formString.split(" ").map((word, index) => {
        const input = inputs.find(
          (input) => input.name === word.replace(/[()]/g, "")
        );
        if (input) {
          return (
            <label key={index}>
              <input
                type="text"
                placeholder={input.name}
                value={input.value}
                onChange={(event) => handleChange(event, index)}
              />
            </label>
          );
        }
        return word + " ";
      })}
      <br />
      <button type="submit">Generate Image</button>
    </div>
  );
};

export default PromptForm;
