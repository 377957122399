import React from "react";
import { styled } from "@mui/material";

const LabelContainer = styled("button")(({ bgImage }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: `transparent url(${bgImage})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  width: 182,
  height: 53,
  "> p": {
    color: "white",
    fontWeight: "bold",
    fontSize: "1.25rem",
  },
}));

export default function CustomLabel({ text, bgImage }) {
  return (
    <LabelContainer bgImage={bgImage}>
      <p>{text}</p>
    </LabelContainer>
  );
}
