import React from "react";
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,
  CardContent,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import axios from "axios";
import { formTypes } from "../../data/formTypes";
import Step from "../Step";
import Img from "./../../images/AI_charades_2.png";
import Star from "./../../images/star.png";

const SelectBox = styled(Box)(({ theme }) => ({
  color: "#fff",
  textAlign: "center",
  "& .MuiInputBase-root": {
    color: "#fff",
    border: "1px dashed #4D4D4D",
    textAlign: "left",
    borderRadius: "10px",
    width: "300px",
    outline: "none",
    boxShadow: "none",
    background: "#131313",
    "& svg": {
      color: "#fff",
    },
    "& fieldset": {
      border: "none",
    },
  },
}));

const FillBlankCard = styled(Box)(({ theme }) => ({
  background: "#000",
  borderRadius: "10px",
  border: "3px solid #fff",
  color: "#979797",
  maxWidth: "320px",
  margin: "0 auto",
  fontSize: "20px",
  lineHeight: "1.9",
  "& label": {
    color: "#fff",
  },
  "& input": {
    padding: "5px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "none",
    outline: "none",
    fontSize: "20px",
    marginBottom: "10px",
  },
}));

const LayoutPromptForm = ({
  setImageArray,
  setInputs,
  inputs,
  setSelectedFormType,
  setLoading,
  selectedFormType,
  showBuyMoreOption,
}) => {
  const handleChange = (event, index) => {
    const values = [...inputs];
    values[index].value = event.target.value;
    setInputs(values);
  };

  const handleSubmit = async (event) => {
    // event.preventDefault();
    setLoading(true);
    // Fill in the blanks in the form string using the form inputs
    let result = formTypes[selectedFormType].prompt;
    inputs.forEach((input) => {
      result = result.replace(`(${input.name})`, input.value);
    });
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER}/prompt`,
      {
        test: result,
      }
    );
    setImageArray(data.arrayOfImages);
    setLoading(false);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <Grid item xs={12} md={4}>
        {/* <Stack spacing={4}> */}
        <Step stepName={"Step 1"} subText={"Draw a Card"} />
        <form onSubmit={handleSubmit} sx={{ minWidth: "200px" }}>
          <SelectBox>
            {/* <InputLabel id="form-label">Select Card</InputLabel> */}
            <Select
              labelId="form-label"
              id="form-label"
              value={selectedFormType}
              label="Select Card"
              onChange={(e) => {
                if (e.target.value === "buy-more") {
                  window.open("https://www.aipuzzlebox.com/", "_blank");
                } else {
                  setSelectedFormType(e.target.value);
                }
              }}
            >
              {formTypes.map((formType, index) => {
                return (
                  <MenuItem key={index} value={formType.value}>
                    {formType.label}
                  </MenuItem>
                );
              })}
              {showBuyMoreOption && (
                <MenuItem
                  sx={{
                    backgroundColor: "#ff0",
                    paddingTop: "0.75rem",
                    paddingBottom: "0.75rem",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "#ff0",
                    },
                  }}
                  key={`form-types-${formTypes.length + 1}`}
                  value="buy-more"
                >
                  Try More Games
                </MenuItem>
              )}
            </Select>
          </SelectBox>
        </form>
      </Grid>

      <Grid item xs={12} md={4}>
        <Step stepName={"Step 2"} subText={"Fill in the blanks!"} />
        {selectedFormType > -1 && (
          <FillBlankCard>
            <CardContent>
              {formTypes[selectedFormType].prompt
                .split("(")
                .map((word, index) => {
                  const words = word.substring(0, word.indexOf(")"));
                  const otherWords = word.substring(word.indexOf(")") + 1);
                  const input = inputs.find((input) => input.name === words);

                  if (input) {
                    return (
                      // console.log('input', input.name.length),
                      <>
                        <label key={index}>
                          <input
                            type="text"
                            placeholder={input.name}
                            value={input.value}
                            onChange={(event) =>
                              handleChange(event, input.inputIndex)
                            }
                            onKeyDown={handleEnter}
                            // style={{ maxWidth: `${input.name.length / 1.8}em` }}
                          />
                        </label>
                        {otherWords && otherWords + " "}
                      </>
                    );
                  }

                  return word + " ";
                })}
            </CardContent>
            <Box p={2} mt={4}>
              <img src={Img} alt="ai generated" />
            </Box>
          </FillBlankCard>
        )}
        <Box textAlign={"center"} mt={2}>
          <Button
            className="dark_button"
            variant="contained"
            onClick={handleSubmit}
          >
            <span>Generate Image</span>
            <div className="star">
              <img src={Star} alt="star_image" />
            </div>
            <div className="star">
              <img src={Star} alt="star_image" />
            </div>
            <div className="star">
              <img src={Star} alt="star_image" />
            </div>
            <div className="star">
              <img src={Star} alt="star_image" />
            </div>
            <div className="star">
              <img src={Star} alt="star_image" />
            </div>
            <div className="star">
              <img src={Star} alt="star_image" />
            </div>
            {/* <div className="star"><img src={Star} alt="star_image" /></div> */}
            {/* <div className="star"><img src={Star} alt="star_image" /></div> */}
            {/* <div className="star"><img src={Star} alt="star_image" /></div> */}
          </Button>
        </Box>
      </Grid>
      {/* </Stack> */}
      {/* </Grid> */}
    </>
  );
};

export default LayoutPromptForm;
