import React, { useContext } from "react";
import { GameContext } from "../../Context/GameContextProvider";

const LeftSide = () => {
  const {
    gameState: { gameData, currentUser, players },
  } = useContext(GameContext);

  return (
    <div
      className="gamePage"
      style={{
        border: "1px solid #FFFFFF",
        padding: "10px",
        borderRadius: "20px",
        margin: "0",
      }}
    >
      <div className="gamePage_startGame players mb-2">
        <div
          className="gamePage_startGame_code"
          style={{ flexDirection: "column", alignItems: "center" }}
        >
          <div className="gamePage_startGame_code_inner">
            <h5>Invite Code</h5>
            <p>{gameData?.code}</p>
          </div>
          <div className="gamePage_startGame_code_inner">
            <h5>Invite URL</h5>
            <p>{gameData?.link}</p>
          </div>
        </div>
        <h2 style={{ margin: "10px 0", textAlign: "center" }}>
          Active players
        </h2>
        <div className="gamePage_startGame_players">
          {players?.map((player) => (
            <div
              className="gamePage_startGame_players_player"
              style={{ width: "max-content", backgroundColor: player?.color }}
              key={player?._id}
            >
              {/* <span className="remove">-</span> */}
              <span className="title">
                Player {player?.player_Serial}
                {player?.userId === currentUser?._id ? "(You)" : null}
              </span>
              {player?.turn ? <span className="status">Active</span> : null}
              {player?.player_type === "AI" ? (
                <span className="status">{player?.player_type}</span>
              ) : null}
            </div>
          ))}
        </div>
        <div
          style={{
            margin: "30px 20px",
            display: "none",
            justifyContent: "center",
            // display: "none",
          }}
        >
          <div className="player_add">
            <button className="button btn">
              <span>+ Add</span>
            </button>
          </div>
          {/* <div className="player_add">
            <button
              className="button btn"
              disabled={joining}
              onClick={() =>
                createPlayersInGame(gameDispatch, {
                  playerType: "AI",
                  code: gameData?.code,
                  join: true,
                })
              }
            >
              <img src={image} alt="" /> <span>+ Add AI</span>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LeftSide;
