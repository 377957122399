import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import React, { useContext, useEffect } from "react";
import { useBeforeunload } from "react-beforeunload";
import { useNavigate, useParams } from "react-router-dom";
import ImageBox from "../../../components/Duel/ImageBox";

// Components
import { GameContext } from "../../../Context/GameContextProvider";
import { SocketContext } from "../../../Context/ScocketContextProvider";
import useCountDown from "../../../Hooks/useCountDown";
import { resetGame, storeGameData } from "../../../Store/game/actions";
import {
  AiWinnerSelect,
  getPlayersInGame,
  updatePlayer,
  winnerSelect,
} from "../../../Store/game/Api";
// import { ReactComponent as ImageConnector } from "./../../../images/image-connector.svg";

const ChooseTheBest = (props) => {
  const { gameCode } = useParams();
  const navigate = useNavigate();
  const { socket } = useContext(SocketContext);

  // console.log(`counter`, counter);
  // const { btnText, btnBg } = props;

  const {
    gameState: {
      loading,
      adding,
      players,
      gameData,
      joining,
      votedPlayer,
      currentUser,
      creator,
    },
    gameDispatch,
  } = useContext(GameContext);

  // console.log(`playerImage`, playerImage, players, votedPlayer);
  const game_code = gameCode || gameData?.code;

  const getPlayer = () => {
    getPlayersInGame(gameDispatch, { game_id: game_code, isSubmitted: true });
  };

  useEffect(() => {
    socket.on(`winner selecting ${game_code}`, () => {
      gameDispatch(storeGameData("adding", true));
    });
    socket.on(`winner select done in ${game_code}`, (data) => {
      console.log("done>>>>>", data);
      const { winner, winnerData, winnerType } = data;
      gameDispatch(storeGameData("winner", winner));
      gameDispatch(storeGameData("winnerData", winnerData));
      gameDispatch(storeGameData("winnerType", winnerType));
      navigate(`/duel/winner/${game_code}`);
    });
    socket.on(`Start game again ${game_code}`, () => {
      gameDispatch(resetGame(navigate));
    });
    getPlayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!gameData?.code) {
      navigate("/duel");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVote = (player, idx) => {
    // console.log("image", player);
    updatePlayer(gameDispatch, {
      body: { count: 1 },
      query: { id: player?._id, gameCode: game_code },
      successCallBack: () => {
        getPlayer();
      },
    });
    let data = {
      votedPlayer: player?._id,
      voter: currentUser?._id,
    };
    gameDispatch(storeGameData("votedPlayer", data));
  };

  const getName = () => {
    if (players?.length === 2) {
      return "Player 1 or Player 2?";
    } else if (players?.length === 3) {
      return "Player 1 or Player 2? Or 3?";
    } else if (players?.length > 3) {
      return "Player 1 or Player 2? Or 3? Or 4?, etc";
    }
  };

  const handleWinner = () => {
    winnerSelect(gameDispatch, {
      gameCode: gameData?.code,
      players,
      navigate,
      socket,
    });
  };

  const handleAiWinner = () => {
    AiWinnerSelect(gameDispatch, {
      gameCode: gameData?.code,
      players,
      navigate,
      socket,
    });
  };

  const playerFilter = players?.filter((el) => el?.isSubmitted);
  // console.log(`playerFilter`, playerFilter);
  const { counter } = useCountDown(
    120,
    () => {
      let players = playerFilter?.reduce(
        (total, item) => total + item?.votingCount,
        0
      );
      if (players > 0) {
        handleWinner();
      } else {
        handleAiWinner();
      }
    },
    playerFilter?.length && !adding && creator?._id === currentUser?._id
  );

  useBeforeunload((event) => {
    // if (value !== '') {
    // console.log(`event`, event);
    event.preventDefault();
    // }
  });

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid item xs={12}>
        <div
          className="gamePage_startGame_start d-none"
          style={{ marginTop: "5px" }}
        >
          <button
            className="btn generate_ai"
            type="button"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              gameDispatch(resetGame(navigate));
            }}
          >
            Exit
          </button>
        </div>
        {playerFilter?.length && (
          <>
            {!adding && (
              <div style={{ textAlign: "center" }}>
                <span style={{ color: "red" }}>{counter}s</span>
              </div>
            )}
            <Box display="flex" flexDirection="column" alignItems="center">
              {/* <span style={{ color: "red" }}>{counter}s</span> */}
              <div className="gamePage_startGame_start">
                <button
                  className="btn"
                  type="button"
                  style={{ marginBottom: "10px" }}
                  // onClick={() => handleWinner()}
                  disabled={adding}
                >
                  Which one do you prefer? {getName()}
                </button>
              </div>
              {creator?._id === currentUser?._id && (
                <div
                  className="gamePage_startGame_start"
                  style={{ marginTop: "5px" }}
                >
                  <button
                    className="btn generate_ai"
                    type="button"
                    disabled={adding}
                    onClick={() => handleAiWinner()}
                  >
                    {adding ? "Selecting..." : "Use the AI Judge"}
                  </button>
                </div>
              )}
            </Box>
          </>
        )}
        {loading && playerFilter?.length > 0 && (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "20px" }}
          >
            <p style={{ color: "white" }}>Loading...</p>
          </div>
        )}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="1.5rem"
          style={{ padding: "0 10px" }}
          flexWrap={"wrap"}
        >
          {loading && playerFilter?.length === 0 ? (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "20px" }}
            >
              <p style={{ color: "white" }}>Loading...</p>
            </div>
          ) : playerFilter?.length ? (
            playerFilter?.map((player, idx) => (
              <React.Fragment key={player?._id}>
                <Box
                  style={{ margin: "0 10px 10px" }}
                  display="flex"
                  justifyContent="center"
                  className="choose"
                >
                  <ImageBox
                    isLoading={false}
                    imageArray={[{ url: player?.images, prompt: "" }]}
                    boxLabel={`Player ${player?.player_Serial}`}
                    joining={joining}
                    player={player}
                    index={idx}
                    onClick={() => {
                      if (joining) return;
                      // if (votedPlayer?.[idx]) return;
                      if (votedPlayer?.votedPlayer) return;
                      handleVote(player, idx);
                    }}
                  />
                </Box>
                {/* {players?.length > 1 && <ImageConnector />} */}
                {players?.length % 2 === 1 && <Box></Box>}
              </React.Fragment>
            ))
          ) : (
            <>
              <Box style={{ minWidth: "400px" }}>
                <div
                  className="gamePage_startGame_start"
                  style={{ marginTop: "5px" }}
                >
                  <button
                    className="btn generate_ai"
                    type="button"
                    style={{ background: "red" }}
                  >
                    Game have no winner
                  </button>
                </div>
                <div
                  className="gamePage_startGame_start"
                  style={{ marginTop: "5px" }}
                >
                  <button
                    className="btn generate_ai"
                    type="button"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      currentUser?._id === creator?._id &&
                        socket.emit("start game again");
                      gameDispatch(resetGame(navigate));
                    }}
                  >
                    {currentUser?._id === creator?._id
                      ? "Start New"
                      : "Exit Game"}
                  </button>
                </div>
              </Box>
            </>
          )}
        </Box>
      </Grid>
    </Slide>
  );
};

export default ChooseTheBest;
