import { Box } from "@mui/material";
import React, { forwardRef, useContext } from "react";
import { GameContext } from "../../Context/GameContextProvider";
import FillInTheBlanks from "../../pages/Duel/fillInTheBlanks";
import { storeGameData } from "../../Store/game/actions";
const PlayerOneBlanks = forwardRef((props, ref) => (
  <FillInTheBlanks ref={ref} {...props} />
));

const WaitForOthers = ({ turnPlayer }) => {
  // console.log(
  //   `🦄 ~ file: WaitForOthers.js:11 ~ WaitForOthers ~ turnPlayer:`,
  //   turnPlayer
  // );
  const {
    gameState: {
      // loading,
      // playerImage,
      // players,
      selectedFormType,
      // gameData,
      playersInput,
    },
    gameDispatch,
  } = useContext(GameContext);

  // console.log(`playerImage`, playerImage, players);
  return (
    <div
      className="waiting"
      style={{ backgroundColor: "#D9D9D9", padding: "20px" }}
    >
      <h2
        style={{ textAlign: "center", color: "#8F2828", margin: "0px 0 10px" }}
      >
        Spectating
      </h2>
      <p style={{ textAlign: "center", color: "#000", margin: "0px 0 10px" }}>
        Player {turnPlayer?.player_Serial || ""}{" "}
        {turnPlayer?.player_type === "AI" ? turnPlayer?.player_type || "" : ""}
      </p>
      <Box
        className="waiting_wrapper"
        display="flex"
        justifyContent="between"
        alignItems={"center"}
      >
        <div className="waitingMain" style={{ minWidth: "50%" }}>
          {/* <ImageBox isLoading={loading} imageArray={playerImage} /> */}
          <form onSubmit={(e) => e.preventDefault()}>
            <PlayerOneBlanks
              stepName={`Player ${turnPlayer?.player_Serial || ""} ${
                turnPlayer?.player_type === "AI"
                  ? turnPlayer?.player_type || ""
                  : ""
              }: Fill in the blanks!`}
              selectedFormType={selectedFormType}
              inputs={playersInput}
              loading={true}
              setInputs={(data) =>
                gameDispatch(storeGameData("playersInput", data))
              }
            />
            <button type="submit" hidden></button>
          </form>
        </div>
        <h4 style={{ color: "#000", marginLeft: "10px" }}>
          Waiting for Submission Watch as the other players finish their turns
        </h4>
      </Box>
    </div>
  );
};

export default WaitForOthers;
