import { axiosServerApi, get, patch, post } from "../../Helpers/api_helper";
import { rerenderGameData, storeGameData, storePlayer } from "./actions";

// creating game
const joinGame = async (
  data,
  dispatch,
  { navigate, currentUser, socket, startGame }
) => {
  dispatch(storeGameData("joining", true));
  try {
    const url = "/games/create-gameroom";
    const join = await post(url, data);
    // console.log("join>>>game", join, currentUser, socket);
    dispatch(storeGameData("gameData", join?.data));
    const body = {
      game_id: join?.data?.code,
      player_type: currentUser?.playerType,
      userid: currentUser?._id,
    };
    joinPlayersToGame(dispatch, {
      data: body,
      url: "/duel/start-game",
      navigate,
      socket,
      currentUser,
      startGame,
    });
    // navigate("/duel/start-game");
  } catch (err) {
    const message = err.response?.data?.message || "Failed to join";
    dispatch(storeGameData("error", message));
  } finally {
    // dispatch(storeGameData("joining", false));
  }
};

// players list
const getPlayersInGame = async (
  dispatch,
  { game_id, isSubmitted = false, currentUser, newTurn, callBack = () => {} }
) => {
  dispatch(storeGameData("loading", true));
  try {
    const url = `/games/game-players?game_id=${game_id}${
      !!isSubmitted ? `&isSubmitted=${isSubmitted}` : ""
    }`;
    const players = await get(url);
    // console.log("players,", players);
    dispatch(storeGameData("players", players?.data));
    callBack(players?.data);
    // let data = [];
    // players?.data?.forEach((el) => {
    //   data.push(false);
    // });
    // dispatch(storeGameData("votedPlayer", data));
    // check updated user
    // const getUpdated = players?.data?.find(
    //   (el) => el?.userId === currentUser?.userId
    // );
    // // update turn
    // if (newTurn && getUpdated?.turn) {
    //   dispatch(storeGameData("activeStep", "player-form"));
    // }
    //  else if (newTurn === "check") {
    //   dispatch(storeGameData("activeStep", "wait-for-others"));
    // }
  } catch (err) {
    const message = err.response?.data?.message || "Failed to join";
    dispatch(storeGameData("error", message));
  } finally {
    dispatch(storeGameData("loading", false));
  }
};

// create player in game func
const createPlayersInGame = async (
  dispatch,
  { playerType = "anonymous", join, code, socket }
) => {
  dispatch(storeGameData("joining", true));
  try {
    const url = `/games/create-player`;
    const players = await post(url, {
      player_type: playerType,
    });
    if (playerType !== "AI") {
      dispatch(storeGameData("currentUser", { ...players?.data, playerType }));
      dispatch(storeGameData("userId", players?.data?.userId));
    }
    const body = {
      game_id: code,
      player_type: playerType,
      userid: players?.data?._id,
    };
    // console.log(`🦄 ~ file: Api.js:65 ~ players`, players);
    join &&
      joinPlayersToGame(dispatch, {
        data: body,
        join: true,
        reCall: true,
        socket,
        currentUser: players,
        playerType,
      });
  } catch (err) {
    const message = err.response?.data?.message || "Failed to create";
    dispatch(storeGameData("error", message));
  } finally {
    if (!join) dispatch(storeGameData("joining", false));
  }
};

// join player to game func
const joinPlayersToGame = async (
  dispatch,
  {
    data,
    navigate,
    redirect = "/duel/start-game",
    join,
    reCall,
    socket,
    currentUser,
    startGame,
    playerType,
  }
) => {
  dispatch(storeGameData("joining", true));
  try {
    const url = `/games/game-players`;
    const players = await post(url, data);
    // console.log(`players>>>API`, players);
    if (join) {
      dispatch(
        storeGameData("gameData", {
          code: data?.game_id,
        })
      );
    }
    // const findUser = players?.find((el) => el?.userId === currentUser?._id);
    // update current user data
    // if (findUser) {
    if (startGame) {
      dispatch(storeGameData("creator", players?.data));
    }
    if (playerType === "AI") {
      dispatch(storePlayer("aiPlayers", players?.data));
    } else {
      dispatch(storeGameData("currentUser", players?.data));
    }
    // }
    // console.log(`data????`, data);
    // socket
    socket.emit("setup", { _id: data?.userid });
    socket.emit("join game", data?.game_id);
    socket.emit(
      "new player",
      { _id: data?.userid, playerType: currentUser?.player_type },
      data?.game_id
    );
    if (!reCall) {
      navigate(redirect);
    } else {
      dispatch(rerenderGameData());
    }
    // if (startGame) socket.emit("game started", data?.game_id);
  } catch (err) {
    const message = err.response?.data?.message || "Failed to create";
    dispatch(storeGameData("error", message));
  } finally {
    dispatch(storeGameData("joining", false));
  }
};

// create user

// /prompt

// image generate func
const generateImage = async (
  dispatch,
  { data, player_Serial, body, socket, playersInput, players, navigate }
) => {
  // console.log(`player_Serial, body`, player_Serial, body);
  dispatch(storeGameData("joining", true));
  try {
    const url = `/prompt`;
    const image = await axiosServerApi.post(url, data);
    // console.log(`image`, image);
    dispatch(storeGameData("playerImage", image?.data?.arrayOfImages));
    const ai = image?.data?.arrayOfImages?.[0]?.url;
    // update player
    await updatePlayer(dispatch, {
      query: body,
      body: { images: ai, isSubmitted: true, prompt: data?.test },
      // update player call back
      successCallBack: () => {
        updateCallBack(dispatch, {
          socket,
          player_Serial,
          gameCode: body?.gameCode,
          playersInput,
          players,
          navigate,
        });
      },
    });
    // await updatePlayer(dispatch, {
    //   query: body,
    //   body: ,
    //   // update player call back
    //   successCallBack: () => {
    //     socket.emit(
    //       "submit",
    //       {
    //         serial: player_Serial,
    //         game_id: body?.gameCode,
    //       },
    //       async () => {
    //         // clear plyers input
    //         if (playersInput) {
    //           await dispatch(
    //             storeGameData(
    //               "playersInput",
    //               playersInput?.map((el) => ({ ...el, value: "" }))
    //             )
    //           );
    //         }
    //         // reRender
    //         await getPlayersInGame(dispatch, {
    //           game_id: body?.gameCode,
    //         });
    //         // step change
    //         if (players?.length === player_Serial * 1) {
    //           socket.emit(`end game`, body?.gameCode);
    //           navigate(`/duel/choose-best/${body?.gameCode}`);
    //         } else {
    //           await dispatch(storeGameData("activeStep", "wait-for-others"));
    //         }
    //       }
    //     );
    //   },
    // });
  } catch (err) {
    const message = err.response?.data?.message || "Failed to create";
    dispatch(storeGameData("error", message));
  } finally {
    dispatch(storeGameData("joining", false));
  }
};

const updateCallBack = async (
  dispatch,
  { socket, player_Serial, gameCode, playersInput, players, navigate }
) => {
  // console.log(`playersInput:`, playersInput);
  socket.emit(
    "submit",
    {
      serial: player_Serial,
      game_id: gameCode,
    },
    async () => {
      // clear plyers input
      if (playersInput?.length) {
        // console.log(`playersInput:`, playersInput);
        await dispatch(
          storeGameData(
            "playersInput",
            playersInput?.map((el) => ({ ...el, value: "" }))
          )
        );
      }
      // reRender
      // await getPlayersInGame(dispatch, {
      //   game_id: gameCode,
      // });
      // step change
      if (players?.length === player_Serial * 1) {
        socket.emit(`end game`, gameCode);
        navigate(`/duel/choose-best/${gameCode}`);
      } else {
        await dispatch(storeGameData("activeStep", "wait-for-others"));
      }
    }
  );
};

// update player func
const updatePlayer = async (
  dispatch,
  { query, body, successCallBack = () => {} }
) => {
  // /update-user-image
  dispatch(storeGameData("joining", true));
  try {
    const url = `/games/update-user-image?id=${query?.id}&gameCode=${query?.gameCode}`;
    const player = await patch(url, body);
    if (player) {
      successCallBack();
    }
  } catch (err) {
    const message = err.response?.data?.message || "Failed to create";
    dispatch(storeGameData("error", message));
  } finally {
    dispatch(storeGameData("joining", false));
  }
};

// get single func
const getSingleGame = async (dispatch, { gameCode }) => {
  // /update-user-image
  dispatch(storeGameData("loading", true));
  try {
    const url = `/games/create-gameroom?gameCode=${gameCode}`;
    const game = await get(url);
    // console.log(`game>>API`, game?.data);
    if (game) {
      dispatch(storeGameData("gameData", game?.data));
    }
  } catch (err) {
    const message = err.response?.data?.message || "Failed to load data";
    dispatch(storeGameData("error", message));
  } finally {
    dispatch(storeGameData("loading", false));
  }
};

// /winner-select
const winnerSelect = async (
  dispatch,
  { gameCode, players, navigate, socket }
) => {
  socket.emit("winner submitting", gameCode);
  // /update-user-image
  dispatch(storeGameData("adding", true));
  try {
    const url = `/games/winner-select?game_id=${gameCode}`;
    const game = await get(url);
    // console.log(`game>>API`, game?.data);
    if (game) {
      await socket.emit(`winner select done`, gameCode, {
        winner: game?.data,
        winnerData: game?.data,
        winnerType: "vote",
      });
      // console.log(`winner`, game?.data);
      await dispatch(storeGameData("winner", game?.data));
      await dispatch(storeGameData("winnerType", "vote"));
      await dispatch(storeGameData("winnerData", game?.data));
      //. socket
      navigate(`/duel/winner/${gameCode}`);
    }
  } catch (err) {
    const message = err.response?.data?.message || "Failed to load data";
    dispatch(storeGameData("error", message));
  } finally {
    dispatch(storeGameData("adding", false));
  }
};

// /winner-select
const AiWinnerSelect = async (
  dispatch,
  { gameCode, players, navigate, socket }
) => {
  socket.emit("winner submitting", gameCode);
  // /update-user-image
  dispatch(storeGameData("adding", true));
  try {
    let hasWinner = false;
    const url = `/games/ai-winner-select?game_id=${gameCode}`;
    let game;
    while (!hasWinner) {
      game = await get(url);
      if (game?.data?.winner) {
        hasWinner = true;
      }
    }
    // console.log(`game>>API`, game?.data);
    if (game) {
      await socket.emit(`winner select done`, gameCode, {
        winner: game?.data?.winner,
        winnerData: game?.data,
        winnerType: "ai",
      });
      // console.log(`winner`, game?.winner);
      await dispatch(storeGameData("winner", game?.data?.winner));
      await dispatch(storeGameData("winnerType", "ai"));
      await dispatch(storeGameData("winnerData", game?.data));
      //. socket
      navigate(`/duel/winner/${gameCode}`);
    }
  } catch (err) {
    const message = err.response?.data?.message || "Failed to load data";
    dispatch(storeGameData("error", message));
  } finally {
    dispatch(storeGameData("adding", false));
  }
};

/// generate Prompt

const generatePrompt = async (
  dispatch,
  { body, successCallBack = () => {} }
) => {
  // console.log(`body`, body);
  // /update-user-image
  dispatch(storeGameData("loading", true));
  try {
    const url = `/games/text-prompt`;
    const prompts = await post(url, body);
    const { data } = prompts;
    if (data) {
      let value = body?.card_text;
      const matchWord = value?.match(/\((.*?)\)/gi) || [];
      const exactWord = data.match(/\((.*?)\)/gi) || [];
      matchWord?.forEach((el, idx) => {
        const word = exactWord?.[idx]?.replace(/[{()}]/gi, "");
        value = value?.replace(el, word);
      });
      // const newData = playersInput?.map((el, idx) => ({
      //   ...el,
      //   value: exactWord[idx],
      // }));
      // console.log(`newData:`, newData);
      // dispatch(storeGameData("playersInput", newData));
      successCallBack(value);
    }
  } catch (err) {
    const message = err.response?.data?.message || "Failed to load data";
    dispatch(storeGameData("error", message));
  } finally {
    dispatch(storeGameData("loading", false));
  }
};

export {
  joinGame,
  getPlayersInGame,
  createPlayersInGame,
  joinPlayersToGame,
  generateImage,
  updatePlayer,
  getSingleGame,
  winnerSelect,
  updateCallBack,
  generatePrompt,
  AiWinnerSelect,
};
