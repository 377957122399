import { Button, Container, Grid, styled } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Picture from "../components/Picture";

const DarkButton = styled(Button)(({theme}) => ({
  background: "#4D4D4D",
  marginTop:"30px",
  "&:hover":{
    background:"#fff",
    color:"#000",
    fontWeight:"700"
  }
}))

const Gallery = () => {
  // set the nextPageToken to the first page (null)
  const [nextPageToken, setNextPageToken] = useState(null);

  //set dark button loading
  const [showLoadButton, setShowButton] = useState(false);

  // set the files array to null
  let [files, setFiles] = useState(null);

  // load the files when the page loads
  useEffect(() => {
    loadImageFiles();

    //show dark button after loading
    setTimeout(() => {
      setShowButton(true)
    },1500)
  }, []);

  // Call the server to load the files
  const loadImageFiles = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER}/azure-storage-files`
    );
    setNextPageToken(data.data.nextPageToken);
    setFiles(data.data.files);
  };

  // load more picture
  const morepicture = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER}/azure-storage-files?nextPageToken=${nextPageToken}`
    );
    setNextPageToken(data.data.nextPageToken);
    setFiles((prev) => [...prev, ...data.data.files]);
  };

  return (
    <div style={{ minHeight: "100vh", padding: "20px 0px" }}>
      <Container maxWidth="xl">
          <div className="pictures">
            <Grid container spacing={5}>
              {files &&
                files.map((d) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={d.id}>
                      <Picture data={d} />
                    </Grid>
                  );
                })}
            </Grid>
          </div>
          {showLoadButton &&
          <div className="morePicture">
            <DarkButton variant="contained" onClick={morepicture}>
              Load More
            </DarkButton>
          </div>
          }
      </Container>
    </div>
  );
};

export default Gallery;
