// import styled from "@emotion/styled";
import { Box, Container, Grid, Slide, styled } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useBeforeunload } from "react-beforeunload";
import { useNavigate } from "react-router-dom";
import Step from "../../../components/Step";
import { GameContext } from "../../../Context/GameContextProvider";
import { SocketContext } from "../../../Context/ScocketContextProvider";
import { formTypes_telephone } from "../../../data/formTypes";
import image from "../../../images/image 15.png";
import { resetGame, storeGameData } from "../../../Store/game/actions";
import {
  createPlayersInGame,
  getPlayersInGame,
  getSingleGame,
} from "../../../Store/game/Api";

// MainDiv is the main container for the page
const MainDiv = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(5),
  },
}));

const StartGame = () => {
  const navigate = useNavigate();
  const { socket } = useContext(SocketContext);
  // console.log(`currentUser`, isConnected);
  const {
    gameState: { gameData, currentUser, players, joining, reRender, creator },
    gameDispatch,
  } = useContext(GameContext);
  // const { gameState } = useContext(GameContext);
  // console.log(`gameData`, creator, currentUser, players);

  const getPlayer = () =>
    getPlayersInGame(gameDispatch, { game_id: gameData?.code });

  const getGame = () =>
    getSingleGame(gameDispatch, { gameCode: gameData?.code });

  console.log("render");

  useEffect(() => {
    socket.on(`players in ${gameData?.code}`, (data) => {
      // console.log("player joined>>>>>");
      getPlayer();
    });

    socket.on(`game started in room ${gameData?.code}`, (topic) => {
      console.log("game start");
      const findTopicName = formTypes_telephone?.find(
        (el) => el?.label === topic
      );
      gameDispatch(storeGameData("selectedFormType", findTopicName?.value));
      gameDispatch(storeGameData("activeStep", "wait-for-others"));
      getGame();
      navigate("/duel/playground");
    });

    // remove

    socket.on(`remove player from ${gameData?.code}`, (player) => {
      // console.log("remove user >>>");
      if (player === currentUser?.player_Serial) {
        gameDispatch(resetGame(navigate));
        // navigate("/duel");
      }
      getPlayer();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    if (!gameData?.code) {
      navigate("/duel");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getGame();
    getPlayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRender]);

  useBeforeunload((event) => {
    // if (value !== '') {
    // console.log(`event`, event);
    event.preventDefault();
    // }
  });

  const handleRemove = (player) => {
    const data = {
      game_id: gameData?.code,
      serial: player?.player_Serial,
    };
    socket.emit("remove player", data, () => {
      getPlayer();
    });
  };

  return (
    <MainDiv className="">
      <Container maxWidth="xl">
        <div
          className="gamePage_startGame_start d-none"
          style={{ marginTop: "5px" }}
        >
          <button
            className="btn generate_ai"
            type="button"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              gameDispatch(resetGame(navigate));
            }}
          >
            Exit
          </button>
        </div>
        <Grid container>
          <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <div className="gamePage">
              <Grid item xs={12}>
                <Step stepName="Step 1: Add Players" />
                <Box maxWidth="300px" margin="0 auto"></Box>
                <div className="gamePage_startGame players mb-2">
                  <div className="gamePage_startGame_players">
                    {players?.map((player) => (
                      <div
                        className="gamePage_startGame_players_player"
                        key={player?._id}
                        style={{ backgroundColor: player?.color }}
                      >
                        {currentUser?.userId ===
                        player?.userId ? null : creator?.userId ? (
                          <span
                            onClick={() => handleRemove(player)}
                            className="remove pointers"
                          >
                            -
                          </span>
                        ) : null}
                        <span className="title">
                          Player {player?.player_Serial}{" "}
                          {player?.userId === currentUser?.userId
                            ? "(You)"
                            : null}
                        </span>
                        {player?.player_type === "AI" ? (
                          <span className="status">{player?.player_type}</span>
                        ) : null}
                      </div>
                    ))}
                  </div>
                  {gameData?.gameStatus === "start" ? null : (
                    <div style={{ margin: "30px 20px" }}>
                      <div className="player_add d-none">
                        <button className="button btn">
                          <img src={image} alt="" /> <span>+ Add Player</span>
                        </button>
                      </div>
                      <div className="player_add">
                        <button
                          className="button btn"
                          disabled={joining}
                          onClick={() =>
                            createPlayersInGame(gameDispatch, {
                              playerType: "AI",
                              code: gameData?.code,
                              join: true,
                              socket,
                            })
                          }
                        >
                          {joining ? (
                            "Adding..."
                          ) : (
                            <>
                              <img src={image} alt="ai" /> <span>+ Add AI</span>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="gamePage_startGame_code">
                    <div className="gamePage_startGame_code_inner">
                      <h5>Invite Code</h5>
                      <p>{gameData?.code}</p>
                    </div>
                    <div className="gamePage_startGame_code_inner">
                      <h5>Invite URL</h5>
                      <p>{gameData?.link}</p>
                    </div>
                  </div>
                  {creator?._id === currentUser?._id && (
                    <div className="gamePage_startGame_start">
                      <button
                        className="btn"
                        type="button"
                        onClick={() => {
                          if (players?.length > 1) {
                            // socket.emit("game started", gameData?.code);
                            navigate("/duel/playground");
                          } else {
                            alert("Not enough player. Please add AI player");
                          }
                        }}
                      >
                        Start Game and Choose Topic
                      </button>
                    </div>
                  )}
                </div>
              </Grid>
            </div>
          </Slide>
          {/* {getActiveStepComponent(activeStep)} */}
        </Grid>
      </Container>
    </MainDiv>
  );
};

export default StartGame;
