// Picture.js
import { IconButton, Skeleton, styled } from "@mui/material";
import React, { useState } from "react";
import Cup from './../images/cup.png';
import Download from './../images/download.png';
import Link from './../images/link.png';
import Shirt from './../images/shirt.png';




const ImageDiv = styled("div")(({theme}) => ({
  borderRadius:"10px",
  overflow:"hidden",
  height:"100%",
  "& img":{
    // width:"512px",
    // height:"512px",
    width:"100%",
    height:"100%",
    objectFit:"cover",
    borderRadius:"10px",
    transition:"0.5s",
    "&:hover":{
      transform: "scale(1.1)"
    }
  }
}))

const TextDiv = styled("div")(({theme}) => ({
  textAlign:"center",
  padding:"15px 0",
  color:"#fff",
  fontSize:"20px",
  fontWeight:"500",
  textTransform: 'capitalize'
}))

const ButtonDiv = styled("div")(({theme}) => ({
  textAlign:"center",
  "& button":{
    background: "#4D4D4D",
    margin:"0 7px",
    padding:"11px",
  },
  "& img":{
    width:"17px",
  }
}))

const Picture = ({ data }) => {

  const PictureContainerLoad = styled("div")(({theme}) => ({
    borderRadius:"15px",
    border:"2px solid #fff",
    padding:"20px",
    display: !loading ? "block" : "none"
  }))

  const PictureContainer = styled("div")(({theme}) => ({
    borderRadius:"15px",
    border:"2px solid #fff",
    padding:"20px",
    display: loading ? "block" : "none"
  }))

  const [loading, setLoading] = useState(false);

  const handleLoadImage = () => {
    setLoading(true)
  }

  return (
    // <div className="picture">
    <>
    <PictureContainerLoad>
      <ImageDiv>
        <Skeleton variant="rectangular" width={500} height={500} sx={{ background: "#fff", height: "100%" }}/>
      </ImageDiv>
    </PictureContainerLoad>

    <PictureContainer>
      <ImageDiv>
        {!loading &&
        <Skeleton variant="rectangular" width={500} height={400} sx={{ background: "#fff", height: "100%" }}/>}
        <img onLoad={handleLoadImage} 
        // src={`https://drive.google.com/uc?export=view&id=${data.id}`} alt={data.name.replace('.png', '')} 
            src={data.azure_storage_url} alt={data.name}
          style={{ display: loading ? 'block' :'none' }}
        />
      </ ImageDiv>
        {/* <div style={{textAlign: 'center', background: 'grey', color: 'white', textTransform: 'capitalize'}}>{data.name.replace('.png', '')}</div> */}
        <TextDiv>
          {/* {data.name.replace('.png', '')} */}
          {data.name}
        </TextDiv>
        <ButtonDiv>
          <IconButton 
          // onClick={() => window.open(`https://www.googleapis.com/drive/v2/files/${data.id}?alt=media&source=downloadUrl`)}
            onClick={() => window.open(`${ data.azure_storage_url }`)}
          >
            <img src={Link} alt="link_icon" />
          </IconButton>
          <IconButton 
          // onClick={() => window.open(`https://drive.google.com/uc?id=${data.id}&export=download`,"_blank")}
            onClick={() => window.open(`${data.azure_storage_url}`)}
          >
            <img src={Download} alt="download_icon" />
          </IconButton>
          <IconButton>
            <img src={Shirt} alt="shirt_icon" />
          </IconButton>
          <IconButton>
            <img src={Cup} alt="cup_icon" />
          </IconButton>
        </ButtonDiv>

    </ PictureContainer> 
    </>
  );
};

export default Picture;