import { Box, Skeleton, styled, Typography } from "@mui/material";
import React from "react";

// Components
import Image from "../Image";

// FillImageBox is the container for the image
const FillImageBoxContainer = styled(Box)(
  ({ maxHeight, maxWidth, background, customStyles }) => ({
    aspectRatio: "1/1",
    background: background || "#000",
    borderRadius: "15px",
    border: "2px solid #fff",
    color: "#979797",
    maxHeight: maxHeight || "475px",
    maxWidth: maxWidth || "475px",
    flex: 1,
    position: "relative",
    padding: "1rem",
    ...customStyles,
  })
);

// Box Label
const ImgContainer = styled("img")(({ imgContainerDistance }) => ({
  width: 136,
  position: "absolute",
  bottom: imgContainerDistance || -83,
  left: 0,
  right: 0,
  margin: "0 auto",
}));

// Custom Label
const CustomLabelContainer = styled("div")(({ imgContainerDistance }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  flexDirection: "column",
  bottom: imgContainerDistance || -61,
  left: 0,
  right: 0,
  margin: "0 auto",
  "> img": {
    width: "8rem",
    margin: "0 auto",
  },
  "> h6": {
    position: "absolute",
    top: 21,
    left: 0,
    right: 0,
  },
}));

export default function FillImageBox({
  isLoading,
  imageArray,
  boxLabel,
  showBoxLabel = true,
  customLabelText,
  customLabelImg,
  maxHeight,
  maxWidth,
  imgContainerDistance,
  picLabel = "",
  background,
  customStyles,
  onClick,
  allowClickImg,
  customSkeletonStyles,
  picLabelStyles,
}) {
  // console.log(`🦄 ~ file: FillImageBox.js:75 ~ imageArray`, imageArray);
  return (
    <FillImageBoxContainer
      background={background}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      customStyles={customStyles}
      onClick={onClick}
    >
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          sx={{ background: "#fff", height: "100%", ...customSkeletonStyles }}
        />
      ) : (
        imageArray.map((element, index) => (
          <Image
            key={index}
            imgIndex={index}
            src={element?.url}
            name={element?.prompt}
            allowClick={allowClickImg}
          />
        ))
      )}
      {picLabel && (
        <Typography
          align="center"
          marginTop={2}
          color="white"
          sx={picLabelStyles}
        >
          {picLabel}
        </Typography>
      )}
      {customLabelText && (
        <CustomLabelContainer>
          <img src={customLabelImg} alt="box label" />
          <Typography variant="h6" textAlign="center" color="white">
            {customLabelText}
          </Typography>
        </CustomLabelContainer>
      )}
      {!customLabelText && showBoxLabel ? (
        <ImgContainer
          src={boxLabel}
          alt="box label"
          imgContainerDistance={imgContainerDistance}
        />
      ) : null}
    </FillImageBoxContainer>
  );
}
