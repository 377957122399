import React, { useState, useContext } from "react";
import { Box, Container, Grid, Skeleton, styled, Alert } from "@mui/material";

import SimplePromptForm from "../components/Form/SimplePromptForm";
import Image from "../components/Image";
import Step from "../components/Step";

import { Context as appContext } from "../context";

// MainDiv is the main container for the page
const MainDiv = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(10),
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(5),
  },
}));

// FillImageBox is the container for the image
const FillImageBox = styled(Box)(({ theme }) => ({
  aspectRatio: "1/1",
  background: "#000",
  borderRadius: "10px",
  border: "3px solid #fff",
  color: "#979797",
  overflow: "hidden",
  maxHeight: "512px",
  // maxWidth: "320px",
  // margin: "0 auto",
  // height: "360px",
}));

const SimplePrompt = () => {
  const [imageArray, setImageArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(appContext);

  return (
    <MainDiv>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          {!currentUser && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "0px !important",
              }}
            >
              <Alert severity="warning" sx={{ maxWidth: 650, width: "80%" }}>
                Please sign-in to use this feature!
              </Alert>
            </Grid>
          )}
          <SimplePromptForm
            setImageArray={setImageArray}
            setLoading={setLoading}
            disabled={!currentUser}
          />
          <Grid item xs={12} md={4}>
            <Step stepName={"Step 2"} subText={"AI Generated Image"} />

            <Box textAlign={"center"}>
              {/* {loading && (
              <Skeleton variant="rectangular" width={210} height={118} />
            )} */}
              {/* {loading && <p>Loading...</p>} */}

              <FillImageBox>
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{ background: "#fff", height: "100%" }}
                  />
                ) : (
                  imageArray.map((element, index) => (
                    <Image
                      key={index}
                      src={element.url}
                      name={element.prompt}
                    />
                  ))
                )}
                {/* <Image
                      src={Pic}
                    /> */}
              </FillImageBox>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
        </Grid>
      </Container>
    </MainDiv>
  );
};

export default SimplePrompt;
