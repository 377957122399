import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

// Components
import SpecialButton from "../../../components/SpecialButton/SpecialButton";
import {
  selectedCardsInitialValues,
  finalResultInitialValues,
} from "../initialValues";

const ActionBtns = ({
  resultLoading,
  updateSelectedCards,
  updateFinalResult,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginTop="2rem"
    >
      <SpecialButton
        btnText="Check It Out In The Gallery"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          // setShowGallery(true);
          navigate("/gallery");
        }}
        disabled={resultLoading}
        customStyles={{
          backgroundColor: "#5523E2",
        }}
      />
      <SpecialButton
        btnText="New Set of Choices"
        disabled={resultLoading}
        onClick={() => {
          updateSelectedCards(selectedCardsInitialValues());
          updateFinalResult(finalResultInitialValues());
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
        customStyles={{
          backgroundColor: "#FF0000",
          marginLeft: "1rem",
        }}
      />
    </Box>
  );
};

export default ActionBtns;
