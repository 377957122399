import React from "react";
import { Button, styled } from "@mui/material";

// Button
const BtnContainer = styled(Button)(({ theme }) => ({
  width: "100%",
  padding: "0.5rem",
  backgroundColor: "transparent",
  color: "#fff",
  fontSize: "1rem",
  border: "1px dashed white",
  borderRadius: "25px",
}));

export default function CustomButton({ text, onClick }) {
  return <BtnContainer onClick={onClick}>{text}</BtnContainer>;
}
