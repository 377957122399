import { styled } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const FooterDiv = styled("footer")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  color: "LightGray",
  fontWeight: "bold",
  backgroundColor: "#131313",
  padding: "1rem 0",
  width: "100%",
  position: "fixed",
  bottom: 0,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const Footer = () => {
  const footerRoutes = [
    {
      path: "/about",
      text: "About Us",
    },
    {
      path: "/contact",
      text: "Contact",
    },
    {
      path: "/terms",
      text: "Terms of Use",
    },
    {
      path: "/privacy",
      text: "Privacy Policy",
    },
  ];
  return (
    <FooterDiv>
      {footerRoutes.map((route, i) => (
        <Link
          to={route.path}
          key={route.path}
          color="LightGray"
          className="footer-route"
        >
          {route.text}
        </Link>
      ))}
    </FooterDiv>
  );
};

export default Footer;
