import React from "react";
import { Box, styled, Typography } from "@mui/material";

// CardHeaderContainer
const CardHeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "2rem",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
  "> .title-container": {
    flex: 1,
    display: "flex",
    alignItems: "center",
    "> .title": {
      color: "#fff",
    },
  },
  "> .selection-container": {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
    },
  },
}));

// SelectionCardContainer
const SelectionCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  flexDirection: "column",
  marginLeft: "0.5rem",
  "> .selection-text-container": {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "0.15rem solid #262626",
    paddingBottom: "0.5rem",
    "> .selection-text": {
      color: "#fff",
      padding: "0.75rem 1rem",
      backgroundColor: "#262626",
      borderRadius: "0.25rem",
      fontSize: "0.75rem",
      minWidth: "10ch",
      maxWidth: "24ch",
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      minHeight: "0.8rem",
    },
  },
  "> .selection-title": {
    color: "#B4B4B4",
    marginTop: "0.5rem",
    textAlign: "center",
    width: "100%",
    fontSize: "0.75rem",
  },
}));

export default function CardHeader({ title, selectedCards, titleAlign }) {
  return (
    <CardHeaderContainer>
      <Box className="title-container">
        <Typography
          className="title"
          variant="h6"
          component="h1"
          textAlign={titleAlign}
          width="100%"
        >
          {title}
        </Typography>
      </Box>
      {selectedCards && (
        <Box className="selection-container">
          {Object.values(selectedCards).map((cardSelection, i) =>
            cardSelection.value ? (
              <SelectionCardContainer
                key={`card_selection_${i}`}
                title={cardSelection.value}
              >
                <Box className="selection-text-container">
                  <Typography
                    className="selection-text"
                    variant="p"
                    component="p"
                  >
                    {cardSelection.value}
                  </Typography>
                </Box>
                <Typography
                  className="selection-title"
                  variant="p"
                  component="p"
                >
                  {cardSelection.title}
                </Typography>
              </SelectionCardContainer>
            ) : null
          )}
        </Box>
      )}
    </CardHeaderContainer>
  );
}
