import { Skeleton, styled } from "@mui/material";
import React, { useContext } from "react";
import { Context as appContext } from "../context";
import { getUserInitials } from "../utils/utils";

const AvatarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 35,
  height: 35,
  backgroundColor: "#5271ff",
  borderRadius: 20,
}));

export default function UserAvatar() {
  const { currentUser, userLoader } = useContext(appContext);

  return (
    <AvatarContainer className="avatar">
      {userLoader ? (
        <Skeleton variant="rounded" width="100%" height="100%" />
      ) : currentUser ? (
        <p>{getUserInitials(currentUser)}</p>
      ) : null}
    </AvatarContainer>
  );
}
