export const selectedCardsInitialValues = () => ({
  genre: { title: "Genre", value: " ", disableIf: "", panelIndex: 0 },
  medium: { title: "Medium", value: "", disableIf: "genre", panelIndex: 1 },
  animal: { title: "Animal", value: "", disableIf: "medium", panelIndex: 2 },
  character: {
    title: "Character",
    value: "",
    disableIf: "animal",
    panelIndex: 3,
  },
  landscape: {
    title: "Landscape",
    value: "",
    disableIf: "character",
    panelIndex: 4,
  },
});

export const finalResultInitialValues = () => ({
  title: "",
  arrayOfImages: [],
});
