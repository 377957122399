import { styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const BtnContainer = styled("button")(({ btnBg, customStyles }) => ({
  backgroundColor: "transparent",
  color: "#fff",
  fontSize: "1.25rem",
  fontWeight: "bold",
  padding: "1rem 1.5rem",
  borderRadius: "30px",
  backgroundImage: `url(${btnBg})`,
  backgroundSize: "contain",
  cursor: "pointer",
  backgroundRepeat: "no-repeat",
  display: "flex",
  ...customStyles,
}));

function SpecialButton({
  btnText,
  btnBg,
  btnIcon,
  onClick,
  disabled,
  customStyles,
  loading,
}) {
  return (
    <BtnContainer
      btnBg={btnBg}
      onClick={onClick}
      disabled={disabled}
      customStyles={customStyles}
    >
      {loading && (
        <CircularProgress size="24px" color="inherit" sx={{ marginRight: 1 }} />
      )}
      {btnIcon && (
        <img
          src={btnIcon}
          style={{ marginRight: 12, marginTop: -7.5 }}
          alt="btn-icon"
        />
      )}
      <span>{btnText}</span>
    </BtnContainer>
  );
}

export default SpecialButton;
