import { useMsal } from "@azure/msal-react";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { resetGame, storeGameData } from "../Store/game/actions";
import gameReducer, {
  gameInitializer,
  gameStorageKey,
  GAME_INITIAL_STATE,
} from "../Store/game/reducer";
import { AuthContext } from "./AuthContextProvider";

export const GameContext = createContext();

const GameContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const { authState } = useContext(AuthContext);
  // console.log(`accounts`, accounts);
  const [state, dispatch] = useReducer(
    gameReducer,
    GAME_INITIAL_STATE,
    gameInitializer
  );

  useEffect(() => {
    // This is a side-effect and belongs in an effect
    localStorage.setItem(gameStorageKey, JSON.stringify(state));
  }, [state]);

  const changeGameTab = (tab) => {
    dispatch(storeGameData("duelTab", tab));
  };

  useEffect(() => {
    if (instance?.initialized && accounts?.length === 0) {
      dispatch(resetGame());
      dispatch(storeGameData("currentUser", {}));
      dispatch(storeGameData("userId", ""));
      navigate("/");
    }
    if (accounts?.length) {
      const data = {
        playerType: "auth",
        ...authState?.user,
      };
      dispatch(storeGameData("currentUser", data));
      dispatch(storeGameData("creator", data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, instance?.initialized]);

  return (
    <GameContext.Provider
      value={{ gameState: state, gameDispatch: dispatch, changeGameTab }}
    >
      {children}
    </GameContext.Provider>
  );
};

export default GameContextProvider;
