import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GameContext } from "../../Context/GameContextProvider";
import profile from "../../images/image 5.png";
import { createPlayersInGame } from "../../Store/game/Api";

const AnonymousTabContent = () => {
  // createPlayersInGame
  const navigate = useNavigate();
  const {
    gameState: { joining, currentUser, userId },
    gameDispatch,
  } = useContext(GameContext);
  return (
    <div className="anonymous_tab">
      <div className="anonymous_tab_content">
        <div className="anonymous_tab_content_profile">
          <img src={profile} alt="" />
        </div>
        <div
          className="anonymous_tab_content_generate pointers"
          onClick={() =>
            userId || currentUser?.userId
              ? {}
              : createPlayersInGame(gameDispatch, { playerType: "anonymous" })
          }
        >
          <h4>Choose a nickname</h4>
          <span value="" className="input">
            {joining
              ? "Creating..."
              : userId || currentUser?.userId || "Click to generate"}
          </span>
        </div>
      </div>
      <div
        className="anonymous_tab_button"
        onClick={() => {
          if (currentUser?.userId || userId) {
            navigate(`/duel/join-game`);
          }
        }}
      >
        <button className="pointers">Start</button>
      </div>
    </div>
  );
};

export default AnonymousTabContent;
