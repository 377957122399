import React from "react";
import { Container, Grid, Paper } from "@mui/material";

const ContactUs = () => {
  return (
    <div style={{ minHeight: "100vh", padding: "20px 0px" }}>
      <Container maxWidth="sm">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              elevation={3}
              style={{
                border: "2px solid grey",
                padding: "30px",
                textAlign: "center",
                backgroundColor: "black",
              }}
            >
              <h1 style={{ color: "white" }}>Contact Us</h1>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} style={{ padding: "30px" }}>
              <h2>Email</h2>
              <p>hi@aicharades.com</p>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} style={{ padding: "30px" }}>
              <h2>Phone</h2>
              <p>415-579-1463</p>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: "30px" }}>
              <h2>Social</h2>
              <p>
                @aicharades
                <br />
                #aicharades
              </p>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ContactUs;
