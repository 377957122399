import {
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  STORE_AUTH_DATA,
} from "./actionTypes";

export const authStorageKey = "ai-puzzle-auth";

export const AUTH_INITIAL_STATE = {
  loading: false,
  error: "",
  user: {},
  token: "",
};

export const initializer = (initialValue = AUTH_INITIAL_STATE) =>
  JSON.parse(localStorage.getItem(authStorageKey)) || initialValue;

const authReducer = (state, action) => {
  switch (action.type) {
    case STORE_AUTH_DATA:
      state = {
        ...state,
        [action.payload.name]: action.payload.data,
      };
      break;
    case LOGIN_USER:
      state = { ...state };
      break;
    case LOGIN_USER_SUCCESS:
      state = { ...state };
      break;
    case LOGIN_USER_FAILED:
      state = { ...state };
      break;
    case LOGOUT_USER:
      state = {
        ...state,
        loading: false,
        error: "",
        user: {},
        token: "",
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default authReducer;
