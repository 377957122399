import React, { useEffect, useState, useRef } from "react";
import { Box, styled, Slide } from "@mui/material";
import axios from "axios";
import { initialBuilderData, allArrays } from "../../data/builderData";

// Components
import SinglePanel from "./SinglePanel";
import FinalResult from "./FinalResult";
import ActionBtns from "./ActionBtns";
import {
  selectedCardsInitialValues,
  finalResultInitialValues,
} from "./initialValues";

// MainDiv is the main container for the page
const MainDiv = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  marginTop: theme.spacing(6),
  paddingBottom: theme.spacing(10),
  maxWidth: 1630,
  width: "75%",
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "95%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const getPromise = (promptTextList) => {
  return axios.post(`${process.env.REACT_APP_SERVER}/form`, {
    prompt: promptTextList,
  });
};

const getSingleImagePromise = (result) => {
  return axios.post(`${process.env.REACT_APP_SERVER}/prompt`, {
    test: result,
  });
};

const panelIndex = {
  genre: 0,
  medium: 1,
  animal: 2,
  character: 3,
  landscape: 4,
};

export default function Builder() {
  const [selectedCards, updateSelectedCards] = useState(
    selectedCardsInitialValues()
  );

  // loading is the state of the loading animation
  const [isLoading, setIsLoading] = useState(false);
  const [resultLoading, setResultLoading] = useState(false);

  // Array of Images
  const [imagesList, updateImagesList] = useState([...allArrays]);
  const [builderData, updateBuilderData] = useState(initialBuilderData());
  const [finalResult, updateFinalResult] = useState(finalResultInitialValues());

  // refs
  const panelsRef = useRef();
  let panelRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

  const handleClickCard = (type, card, pIndex) => {
    const updatedSelectedCards = { ...selectedCards };
    updatedSelectedCards[type].value = card.original;
    updatedSelectedCards[type].pIndex = pIndex;
    updateSelectedCards(updatedSelectedCards);

    if (type === "landscape") {
      handleGenerateResult(updatedSelectedCards);
      window.scrollTo({
        top: panelRefs[panelIndex[type] + 1].current.offsetTop,
        behavior: "smooth",
      });
    } else {
      const updatedBuilderData = [...builderData];
      updatedBuilderData[pIndex + 1] = updatedBuilderData[pIndex + 1].map(
        (item) => `${card.prompt} ${item}`
      );
      updateBuilderData(updatedBuilderData);

      handleGetImageArray(panelIndex[type] + 1, updatedBuilderData);
      window.scrollTo({
        top:
          panelIndex[type] === 3
            ? panelRefs[panelIndex[type] + 1].current.offsetTop - 40
            : panelRefs[panelIndex[type] + 1].current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleGenerateResult = async (cards) => {
    setResultLoading(true);
    const finalText = Object.keys(panelIndex).reduce(
      (sum, acc) => `${sum} ${cards[acc].value}`,
      ""
    );
    try {
      const response = await getSingleImagePromise(finalText);
      updateFinalResult({
        title: finalText,
        arrayOfImages: response.data.arrayOfImages,
      });
      setResultLoading(false);
    } catch (error) {
      setResultLoading(false);
      console.log("Get Result Image Error: ", error);
    }
  };

  const handleGetImageArray = async (panelIndex, updatedBuilderData) => {
    setIsLoading(true);
    try {
      const response = await getPromise(updatedBuilderData[panelIndex]);
      const finalList = [...allArrays];
      finalList[panelIndex] = response.data.arrayOfImages.map((item, i) => ({
        ...item,
        original: finalList[panelIndex][i].original,
      }));
      updateImagesList(finalList);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Get Images Error: ", error);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <MainDiv>
      <Slide
        ref={panelsRef}
        direction="left"
        in={true}
        mountOnEnter
        unmountOnExit
      >
        <div>
          {Object.keys(panelIndex).map((panel, i) => {
            let isDisabled = false;
            if (!!selectedCards[panel].disableIf) {
              isDisabled =
                selectedCards[selectedCards[panel].disableIf]?.value.trim() ===
                "";
            }

            const title = `Step ${i + 1}: ${
              selectedCards[panel]?.value?.trim()
                ? "Card Selected"
                : "Select a card or input your own!"
            }`;

            return (
              <SinglePanel
                key={`builder_${panel}_${i}`}
                ref={panelRefs[i]}
                panel={panel}
                title={title}
                selectedCards={selectedCards}
                imagesList={imagesList}
                panelIndex={panelIndex}
                isLoading={isLoading && i > 0}
                isDisabled={isDisabled}
                handleClickCard={handleClickCard}
              />
            );
          })}
          {!finalResult.title && !resultLoading && (
            <ActionBtns
              resultLoading={resultLoading}
              updateSelectedCards={updateSelectedCards}
              updateFinalResult={updateFinalResult}
            />
          )}
          <div ref={panelRefs[5]} style={{ minHeight: 500 }}>
            {resultLoading || !!finalResult.arrayOfImages.length ? (
              <FinalResult
                selectedCards={selectedCards}
                finalResult={finalResult}
                isLoading={resultLoading}
              />
            ) : null}
          </div>
          {(!!finalResult.title || resultLoading) && (
            <ActionBtns
              resultLoading={resultLoading}
              updateSelectedCards={updateSelectedCards}
              updateFinalResult={updateFinalResult}
            />
          )}
        </div>
      </Slide>
    </MainDiv>
  );
}
