import React, { forwardRef } from "react";
import { Box, styled, CardContent, Grid, Slide } from "@mui/material";
import "./style.css";

// Components
import Step from "../../components/Step";
import SpecialButton from "../../components/SpecialButton/SpecialButton";
import FillImageBox from "../../components/FillImageBox/FillImageBox";
import CustomLabel from "../../components/CustomLabel";
import Img from "../../images/AI_charades_2.png";

const FillBlankCard = styled(Box)(({ theme }) => ({
  background: "#000",
  borderRadius: "10px",
  border: "3px solid #fff",
  color: "#979797",
  maxWidth: "320px",
  margin: "0 auto",
  fontSize: "20px",
  lineHeight: "1.9",
  "& label": {
    color: "#fff",
  },
  "& input": {
    padding: "5px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "none",
    outline: "none",
    fontSize: "20px",
    marginBottom: "10px",
  },
}));

const DescribeImage = forwardRef((props, ref) => {
  const {
    handleOnClickBtn,
    stepName,
    btnText,
    btnBg,
    stepVariant,
    playerInput,
    updatePlayerInput,
    labelText,
    labelImg,
    isLoading,
    prevPlayerArrayOfImages,
  } = props;

  return (
    <Slide ref={ref} direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid container width="75%" margin="0 auto">
        <Grid item xs={12} sm={12} md={6} paddingTop="2rem">
          <Step variant={stepVariant} stepName={stepName} />
          <FillBlankCard>
            <CardContent>
              <textarea
                placeholder="Describe the Image"
                value={playerInput[0].value}
                onChange={(ev) => {
                  updatePlayerInput([
                    {
                      name: "fictional character",
                      value: ev.target.value,
                      inputIndex: 0,
                    },
                  ]);
                }}
                rows={20}
                className="describe_image"
                disabled={isLoading}
              />
            </CardContent>
            <Box px={2}>
              <img src={Img} alt="ai generated" />
            </Box>
          </FillBlankCard>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop="1.5rem"
          >
            <SpecialButton
              btnBg={btnBg}
              btnText={btnText}
              onClick={handleOnClickBtn}
              disabled={isLoading}
              loading={isLoading}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box
            display="flex"
            justifyContent="center"
            marginRight="3rem"
            marginBottom="1.5rem"
          >
            <CustomLabel text={labelText} bgImage={labelImg} />
          </Box>
          <FillImageBox
            isLoading={false}
            imageArray={prevPlayerArrayOfImages}
            showBoxLabel={false}
          />
        </Grid>
      </Grid>
    </Slide>
  );
});

export default DescribeImage;
