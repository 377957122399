import styled from "@emotion/styled";
import { Box, Button, CardContent, Grid } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import Step from "../Step";
import Img from "./../../images/AI_charades_2.png";
import Star from './../../images/star.png'
import Fortune from './../../images/magic-ball.png';

const FillBlankCard = styled(Box)(({ theme }) => ({
  background: "#000",
  borderRadius: "10px",
  border: "3px solid #fff",
  color: "#979797",
  maxWidth: "320px",
  margin: "0 auto",
  fontSize: "20px",
  lineHeight: "1.9",
  "& label": {
    color: "#fff",
  },
  "& textarea": {
    padding: "5px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "none",
    outline: "none",
    fontSize: "20px",
    marginBottom: "10px",
    width:"97% !important",
    height:"200px",
    color: 'black'
  },
}));

const SimplePromptForm = ({ setLoading, setImageArray, disabled }) => {
  const [inputValue, setInputValue] = useState("");

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // event.preventDefault();
    setLoading(true);
    // Fill in the blanks in the form string using the form inputs
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER}/prompt`,
      {
        test: inputValue,
      }
    );
    setImageArray(data.arrayOfImages);
    setLoading(false);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <>
      <Grid item xs={12} md={2}></Grid>
      <Grid item xs={12} md={4}>
        <Step stepName={"Step 1"} subText={"Enter your prompt"} />
        <FillBlankCard>
          <CardContent>
            <form>
              <label>
                <textarea
                  type="text"
                  placeholder="Enter here"
                  value={inputValue}
                  onChange={(event) => handleChange(event)}
                  onKeyDown={handleEnter}
                  disabled={disabled}
                  // style={{ maxWidth: `${input.name.length / 1.8}em` }}
                />
              </label>
            </form>
          </CardContent>
          <Box p={2} mt={2}>
            <img src={Img} alt="ai generated" />
          </Box>
        </FillBlankCard>
        <Box textAlign={"center"} mt={2}>
        <Button className="dark_button" variant="contained" onClick={handleSubmit} disabled={disabled}>
            <span>Generate Image  <img src={Fortune} alt="magic_logo" /></span>
            <div className="star"><img src={Star} alt="star_image" /></div>
            <div className="star"><img src={Star} alt="star_image" /></div>
            <div className="star"><img src={Star} alt="star_image" /></div>
            <div className="star"><img src={Star} alt="star_image" /></div>
            <div className="star"><img src={Star} alt="star_image" /></div>
            <div className="star"><img src={Star} alt="star_image" /></div>
            {/* <div className="star"><img src={Star} alt="star_image" /></div> */}
            {/* <div className="star"><img src={Star} alt="star_image" /></div> */}
            {/* <div className="star"><img src={Star} alt="star_image" /></div> */}
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default SimplePromptForm;
