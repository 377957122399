import "../styles/builder.css";

function Image({
  src,
  heading,
  promptIndex,
  setFullPrompt = () => {},
  getImages,
  imgIndex,
  greyImages = () => {},
  saveImage = () => {},
  finalRow,
  imageArray,
  allowClick = true,
  message,
}) {
  // console.log(`🦄 ~ file: Image.js:16 ~ src`, src);
  let max = 2;

  function handleClick(event) {
    // console.log(finalRow, promptIndex);

    if (finalRow) {
      // console.log(imageArray[promptIndex][imgIndex].url);
      saveImage(heading, imageArray[promptIndex][imgIndex].url);
    }

    setFullPrompt(heading);
    if (promptIndex + 1 <= max) {
      getImages(promptIndex + 1, heading);
    }

    greyImages(event);
  }

  return (
    <div
      id={`imgContainer${imgIndex}`}
      href={`#promptInput${promptIndex + 1}`}
      style={{ cursor: "pointer", borderRadius: "10px" }}
      className="imageContainer"
      onClick={(event) => {
        if (allowClick) {
          handleClick(event);
        }
      }}
    >
      <div
        id={`imgWrapper${imgIndex}`}
        className="imageWrapper"
        style={{
          backgroundImage: `url(${src})`,
        }}
      >
        {/* <img className= 'galleryImage' src={src} alt="" /> */}
        {heading && <p className="description">{heading}</p>}
        {message && (
          <p className="message" style={{ textAlign: "center", width: "100%" }}>
            {message}
          </p>
        )}
      </div>
    </div>
  );
}

export default Image;
