/**
 * Extract the values in paranthesis using a regular expression
 * and return an array of objects with the name and value
 */

export function stringToArray(str) {
  const regex = /\((.+?)\)/g;
  let match;
  const inputs = [];

  while ((match = regex.exec(str))) {
    inputs.push({
      name: match[0].replace("(", "").replace(")", ""),
      value: "",
      inputIndex: inputs.length,
    });
  }
  return inputs;
}

export function getUserInitials(currentUser) {
  const { givenName, surname } = currentUser;

  if (givenName === null || surname === null) {
    return "";
  }
  
  const givenNameFirstChar = givenName[0];
  const surnameFirstChar = surname ? surname[0] : "";
  return givenNameFirstChar + surnameFirstChar;
}
